import React, { useState } from "react";
import { Navbar, NavDropdown } from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import ArrowDown from "../imgs/icons/arrowdown.png";

export default function DropDownMenu(props) {
  const [show, setShow] = useState(false);
  const navigate = useNavigate()
  const { title, data_array } = props;
  const listingurl = (url) =>{
    
    navigate(url)
    
  }
  return (
    <Navbar bsPrefix="pull-left">
      <NavDropdown
        title={
          <>
            {title}
            <img className="thumbnail-image"  src={ArrowDown} alt="arrowdown"/>
          </>
        }
        show={show}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        style={{
          background: "rgba(3, 3, 3, 0.3)",
          border: "none",
          borderRadius: "0px",
          letterSpacing: "0.1em",
          textTransform: "uppercase",
          padding: "10px",
          fontWeight: "normal",
          color: "#fff",
          transition: '0.2s',
        }}
      >
        {data_array.map((ele) => {
          const url = `/listing/${ele[1]}`;
          return (
            
              <NavDropdown.Item bsPrefix="btn-nav-drop" onClick={() => {listingurl(url)}}  style={{ color: "white" }}>
                {ele[0]}
              </NavDropdown.Item>
           
          );
        })}
      </NavDropdown>
    </Navbar>
  );
}
