import React, { useEffect, useState, useContext } from "react";
import { Label } from "reactstrap";
import { Alert } from "antd";
import AddBusiness from "../../imgs/AddBusiness.png";
import { CreateListingGlobals } from "./ListingForm";
import { TagsInput } from "react-tag-input-component";
import Places from "../../pages/places";
import { MapGlobals } from "../../App";
import Select from "react-select";


function BusinessList() {
  const {
    setPageNumber,
    listing,
    setListing,
    menus,
    setMenu,
    services,
    setServices,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    categoryType,
    setCategoryType,
    selected, 
    setSelected,
  } = useContext(CreateListingGlobals);
  const {location, setLocation} = useContext(MapGlobals);

  const [showStatus, setStatusAlert] = useState("");
  const [showMessage, setMessageAlert] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [categoriesList, setCategoriesList] = React.useState([]);
  const [getservices, setgetservices] = useState([])

  const tempArr = [];
  const handleselectChange = (selectedOption) => {
    selectedOption.map((optionValue) => {
      tempArr.push(optionValue.value)
      setServices(tempArr)

      return(
      setSelected(tempArr)
      )
      
    });
  };

  const handleInput = (e) => {
    e.preventDefault();
    setListing({ ...listing, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    getCategories();
    setLocation(null);
  }, []);
  const getCategories = async () => {
    const response = await fetch(
      "https://test-wrangler.listing.workers.dev/api/get-sub-categories",
      {
        method: "GET",
      }
    );
    const data = await response.json();
    console.log("Parent Categories", data.results);
    setCategoriesList(data.results);
  };
  const handleClick = (e) => {
    e.preventDefault();
    if (listing.name === "") {
      setMessageAlert("Please Enter Listing Name");
      setStatusAlert("error");
      setShowAlert(true);
    } else if (listing.url === "") {
      setMessageAlert("Please Enter URL");
      setStatusAlert("error");
      setShowAlert(true);
    } else if (categoryType === 0) {
      setMessageAlert("Please Select Category");
      setStatusAlert("error");
      setShowAlert(true);
    } else if (listing.phone_number === "") {
      setMessageAlert("Please Enter Your Business Number");
      setStatusAlert("error");
      setShowAlert(true);
    } else if (startTime === null) {
      setMessageAlert("Please Enter Your Availablity Start Time");
      setStatusAlert("error");
      setShowAlert(true);
    }else if (endTime === null) {
      setMessageAlert("Please Enter Your Availablity End Time");
      setStatusAlert("error");
      setShowAlert(true);
    }else if (minPrice === 0) {
      setMessageAlert("Please Enter Menus Minimum Price");
      setStatusAlert("error");
      setShowAlert(true);
    }else if (maxPrice === 0) {
      setMessageAlert("Please Enter Menus Maximum Price");
      setStatusAlert("error");
      setShowAlert(true);
    }else if (menus.lenght === 0) {
      setMessageAlert("Please Enter Menu Types");
      setStatusAlert("error");
      setShowAlert(true);
    } else if (services.length === 0) {
      setMessageAlert("Please Enter Services");
      setStatusAlert("error");
      setShowAlert(true);
    } else if (listing.description === "") {
      setMessageAlert("Please Enter Description");
      setStatusAlert("error");
      setShowAlert(true);
    } else {
      console.log("menus", menus);
      console.log("services: ", services);
      console.log("categoryType: ", categoryType);
      console.log("listing data: ", listing);
      console.log("Min price", minPrice);
      console.log("Max Price", maxPrice);
      setPageNumber(3);
    }
  };

  const getServicesById = async (id) => {
    setgetservices([])
    try {
      const res = await fetch(
        `https://test-wrangler.listing.workers.dev/api/get-service-by-id/${id}`,
        {
          method: "GET",
        }
      );
      const result = await res.json();
      console.log(result, "sdsfgf");
      setgetservices(result);
      //console.log("user data", result.results[0]);
      // console.log("user data", userData);
    } catch (e) {
      console.log("error", e);
    }
  };

  return (
    <div>
      <div className="add-listing-form-wrapper">
        <div className="businessFormWrapper">
          <img src={AddBusiness} alt="..." width="29" height="29" />
          <p className="formHeading">List Your Business</p>
        </div>
        <div className="login-form">
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <Label className="edit-input-label">Business Name</Label>
                <input
                  type="text"
                  name="name"
                  onChange={handleInput}
                  value={listing.name}
                  className="edit-form-control"
                  placeholder="Business Name"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <Label className="edit-input-label">
                  Business's Website URL
                </Label>
                <input
                  type="text"
                  name="url"
                  onChange={handleInput}
                  value={listing.url}
                  className="edit-form-control"
                  placeholder="www.example.com"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <Label className="edit-input-label">
                  Select Your Business’s Category
                </Label>
                <select
                  className="edit-form-control"
                  name="categoryType"
                  value={categoryType}
                  onChange={(e) => {setCategoryType(e.target.value); getServicesById(e.target.value);}}
                >
                  <option value={0}>--Select--</option>
                  {categoriesList?.map((element) => {
                    return <option value={element.id}>{element.name}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <Label className="edit-input-label">Business Phone No</Label>
                <input
                  type="tel"
                  name="phone_number"
                  onChange={handleInput}
                  value={listing.phone_number}
                  className="edit-form-control"
                  placeholder="+923455553532"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <Label className="edit-input-label">
                  Set Your Availability
                </Label>
                <div className="row">
                  <div className="col-md-6">
                    <input
                      type="time"
                      name="startTime"
                      onChange={(e) => setStartTime(e.target.value)}
                      value={startTime}
                      className="edit-form-control"
                      placeholder="Set Your Availability"
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="time"
                      name="endTime"
                      onChange={(e) => setEndTime(e.target.value)}
                      value={endTime}
                      className="edit-form-control"
                      placeholder="Set Your Availability"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <Label className="edit-input-label">
                  Set Your Menu Price Range($)
                </Label>
                <div className="row">
                  <div className="col-md-6">
                    <input
                      type="number"
                      name="minPrice"
                      onChange={(e) => setMinPrice(e.target.value)}
                      value={minPrice}
                      className="edit-form-control"
                      placeholder="Min Price"
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="number"
                      name="maxPrice"
                      onChange={(e) => setMaxPrice(e.target.value)}
                      value={maxPrice}
                      className="edit-form-control"
                      placeholder="Max Price"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="row mb-3">
            <div className="col-md-6">
              <Label className="edit-input-label">Add Menu</Label>
              <TagsInput
                value={menus}
                onChange={setMenu}
                name="Menu"
                placeHolder="Add Menu"
              />
            </div>
            <div className="col-md-6">
              <Label className="edit-input-label">Add Services</Label>
                <Select
                  isMulti
                  // components={animatedComponents}
                  closeMenuOnSelect={false}
                  options={getservices}
                  onChange={handleselectChange}
                  autoFocus={false}
                />
            </div>
          </div>
          <Places/>
          <div className="mb-3 mt-3">
            <Label className="edit-input-label">Description</Label>
            <textarea
              type="about"
              name="description"
              value={listing.description}
              onChange={handleInput}
              className="about-input"
              placeholder="Say something about your business"
              rows={8}
              cols={40}
            />
          </div>
          {showAlert ? (
            <Alert message={showMessage} type={showStatus} />
          ) : (
            <div></div>
          )}
          <div className="addlistingbtnwrapper">
            <div className="row addlistingformBtn">
              <div className="col-md-6">
                <button
                  className="bg-white-cusd"
                  onClick={() => setPageNumber(1)}
                >
                  BACK
                </button>
              </div>
              <div className="col-md-6">
                <button className="btn btn-primary" onClick={handleClick}>
                  NEXT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessList;
