import React, {createContext, useEffect, useState} from 'react'
import Footer from '../inc/Footer';
import Header from '../inc/Header';
import AddBusinessTermsAndConditions from './AddBusinessTermsAndConditions';
import AddListingBanner from './AddListingBanner';
import BusinessList from './BusinessList'
import PersonalInfo from './PersonalInfo';
import ProgressBar from './ProgressBar';
import UploadImage from './UploadImages';
export const CreateListingGlobals = createContext();

function ListingForm() {
  const [pagenumber, setPageNumber] = React.useState(1);
  const [showStatus, setStatusAlert] = useState("");
  const [showMessage, setMessageAlert] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [minPrice, setMinPrice] = useState("");
    const [maxPrice, setMaxPrice] = useState("");
    const [categoryType, setCategoryType] = useState(0);
    const [imageData,setImageData] = useState([]);
    const [selected, setSelected] = useState(null);
   const [updatedAbout, setUpdatedAbout] = useState("");
    const [userData, setUserData]= useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    about: "",
    role: "",
    });
    const [menus, setMenu] = useState([]);
    const [services, setServices] = useState([]);
    const [listing, setListing] = useState({
      user_id: "",
      location: "",
      category_id: "",
      url: "",
      description: "",
      name: "",
      address: "",
      phone_number: ""
    });

  useEffect(()=>{
    getUserData();
  },[]);

  const getUserData= async(e)=>{
    try {
      const res = await fetch(
        `https://test-wrangler.listing.workers.dev/api/get-token-info`,
        {
          method: "POST",
          headers: {"authorization": localStorage.getItem("token")},
        }
      );
      const result = await res.json();
      //console.log("user data", result.results[0]);
      setUserData(result.results[0]);
      console.log("user data", userData);
    } catch (e) {
      console.log("error", e);
    }
  };

  const UpdateUser = async (e) => {
    e.preventDefault();
    
      try {
        const res = await fetch(
          `https://test-wrangler.listing.workers.dev/api/save-user/${userData.id}`,
          {
            method: "POST",
            body: JSON.stringify({
              first_name: userData.first_name,
              last_name: userData.last_name,
              about: userData.about,
              password: userData.password,
              user_role: userData.user_role,
            }),
          }
        );
        const result = await res.json();
      } catch (e) {
        console.log("error", e);
      }
  };

  

  

  const FormSubmission =()=>{
    ///
  }

  return (
    <CreateListingGlobals.Provider 
    value={{
      pagenumber: pagenumber,
      setPageNumber: setPageNumber,
      userData: userData,
      setUserData: setUserData,
      FormSubmission: FormSubmission,
      showStatus:showStatus, 
      setStatusAlert:setStatusAlert,
      showMessage:showMessage,
      setMessageAlert:setMessageAlert,
      showAlert:showAlert,
      setShowAlert:setShowAlert,
      listing: listing,
      setListing: setListing,
      menus: menus, 
      setMenu: setMenu,
      services: services, 
      setServices: setServices,
      startTime: startTime,
      setStartTime: setStartTime,
      endTime: endTime,
      setEndTime: setEndTime,
      minPrice: minPrice,
      setMinPrice: setMinPrice,
      maxPrice: maxPrice,
      setMaxPrice: setMaxPrice,
      categoryType: categoryType, 
      setCategoryType:setCategoryType,
      imageData: imageData,
      setImageData: setImageData,
      selected: selected, 
      setSelected: setSelected,
      updatedAbout: updatedAbout, 
      setUpdatedAbout: setUpdatedAbout,
     
    }} >
      <Header/>
      <AddListingBanner/>
      <ProgressBar/>
      {pagenumber === 1 ? <PersonalInfo/> : ''}
      {pagenumber === 2 ? <BusinessList/> : ''}
      {pagenumber === 3 ? <UploadImage/> : ''}
      {pagenumber === 4 ? <AddBusinessTermsAndConditions/> : ''}

      <Footer/>
  </CreateListingGlobals.Provider>  )
}

export default ListingForm