import React from "react";
import { Link } from "react-router-dom";
import house from "../imgs/icons/house.png";
import dashboard from "../imgs/icons/dashboard.png";
import reporting from "../imgs/icons/reporting.png";
import Vector from "../imgs/icons/Vector.png";
import DashLogo from "../imgs/Inspo_black.png";
import { useNavigate } from "react-router-dom";
function DashboardSideNavigationBar() {
  const url = window.location.pathname.split("/")[2];
  const navigate = useNavigate();
  const clearToken = () => {
      localStorage.clear()
      navigate("/home")
  }
  return (
    <div className="dash-side-header">
      <div className="dash-logo">
        <img src={DashLogo} alt="..." />
      </div>
      <ul className="nav-side-links">
        <li>
          <Link to="/">
            <img src={house} alt="..." />
            Visit Site
          </Link>
        </li>
        <li>
          <Link to="/admin/user">
            <img src={Vector} alt="..." />
            <span className={`${url === "user" ? "bordered" : ""} `}>
              Users
            </span>
          </Link>
        </li>
        <li>
          <Link to="/admin/categories">
            <img src={dashboard} alt="..." />
            <span className={`${url === "categories" ? "bordered" : ""} `}>
              Categories
            </span>
          </Link>
        </li>
        <li>
          <Link to="/admin/listing">
            <img src={dashboard} alt="..." />

            <span className={`${url === "listing" ? "bordered" : ""} `}>
              Listing
            </span>
          </Link>
        </li>
        <li>
          <Link to="/admin/services">
            <img src={dashboard} alt="..." />

            <span className={`${url === "services" ? "bordered" : ""} `}>
              services
            </span>
          </Link>
        </li>
        
        <li>
          <Link onClick={clearToken}>
            <img src={reporting} alt="..." />
            Logout
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default DashboardSideNavigationBar;
