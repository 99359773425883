import React, { useState, createContext, useRef } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import ListingPageFilters from "./ListingPageFilters";
import Pagination from "./Paginaton";
import Tabs from "./Tabs";
import Map from "./Map";
import { CircularProgress } from "@mui/material";
import listingOne from "../imgs/listingOne.png";
import review from "../imgs/review.png";
import verify from "../imgs/verify.png";
import message from "../imgs/icons/message.png";
import graph from "../imgs/icons/search.png";
import NORESULT from "../imgs/no-data.png";

export const ListingBodyGlobals = createContext();
function ListingBody(props) {
  const { id } = useParams();
  const paramsId = id ? id : "0";
  const [limit, setLimit] = useState("5");
  const [loader, setLoader] = useState(false);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const [items, setItems] = useState([]);
  const url = useQuery();
  const page = url.get("page") || 1;
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const [intialpage, setInitalpage] = useState(0);
  const [showPagination, setshowPagination] = useState(true);
  const [filterState, setFilterState] = useState(false);
  const [getServices, setGetServices] = useState([]);
  const [serviceFilter, setServiceFilter] = useState([]);
  const [priceStyle, setPriceStyle] = useState("");
  const [ratingStyle, setRatingStyle] = useState("");
  const [isChecked, setisChecked] = useState([]);
  const childCompRef = useRef();
  const [checkbox, setCheckBox] = useState(false);
  const [tabsFlag, setTabsFlag] = useState(false);
  const [flag, setFlag] = useState(false);
  let query = params.get("query") || "";
  const [filters, setFilters] = useState({
    price: null,
    rating: null,
    service: [],
  });
  const data = async () => {
    setCategoriesList([]);
    if (!filterState) {
      setshowPagination(false);
      setLoader(true);
      try {
        const res = await fetch(
          id === undefined
            ? `https://test-wrangler.listing.workers.dev/api/get-category-data/0?page=${page}&limit=${limit}&query=${query}`
            : `https://test-wrangler.listing.workers.dev/api/get-category-data/${paramsId}?page=${page}&limit=${limit}&query=${query}`,
          {
            method: "GET",
          }
        );
        const result = await res.json();
        setCategoriesList(result?.data);
        setLoader(false);
        setGetServices(result.services);
        setshowPagination(true);
        setFlag(true);
        setServiceFilter(result.category_service);
        setCount(result?.count);
      } catch (err) {
        console.log(err.message);
      }
    } else {
      setCategoriesList([]);

      setshowPagination(false);
      setLoader(true);
      const res = await fetch(
        `https://test-wrangler.listing.workers.dev/api/get-all-listing-filtered/${paramsId}?page=${page}&limit=${limit}&query=${query}`,
        {
          method: "POST",
          body: JSON.stringify({
            filters: filters,
          }),
        }
      );
      const result = await res.json();
      setLoader(false);
      setCategoriesList(result.results);
      setGetServices(result.services);
      setisChecked([]);
      setshowPagination(true);
      setCount(result?.count);
    }
  };
  const data1 = async (page) => {
    setCategoriesList([]);
    if (!filterState) {
      setLoader(true);
      try {
        const res = await fetch(
          id === undefined
            ? `https://test-wrangler.listing.workers.dev/api/get-category-data/0?page=${page}&limit=${limit}&query=${query}`
            : `https://test-wrangler.listing.workers.dev/api/get-category-data/${paramsId}?page=${page}&limit=${limit}&query=${query}`,
          {
            method: "GET",
          }
        );
        const result = await res.json();
        setLoader(false);
        setCategoriesList(result?.data);
        setGetServices(result.services);
        setFlag(true);
        setCount(result?.count);
      } catch (err) {
        console.log(err.message);
      }
    } else {
      setLoader(true);
      const res = await fetch(
        `https://test-wrangler.listing.workers.dev/api/get-all-listing-filtered/${paramsId}?page=${page}&limit=${limit}`,
        {
          method: "POST",
          body: JSON.stringify({
            filters: filters,
          }),
        }
      );
      const result = await res.json();
      setLoader(false);
      setCategoriesList(result.results);
      setGetServices(result.services);
      setisChecked([]);
      setCount(result?.count);
    }
  };

  const [_categoriesList, setCategoriesList] = React.useState([]);
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    data();
    setInitalpage(0);
  }, [query, paramsId]);

  const handlePageClick = (data) => {
    let currentPage = data.selected + 1;
    const commentsFormServer = data1(currentPage);
    setInitalpage(data.selected);
    setItems(commentsFormServer);
  };

  return (
    <ListingBodyGlobals.Provider
      value={{
        setisChecked: setisChecked,
        setPriceStyle: setPriceStyle,
        setRatingStyle: setRatingStyle,
        setFilterState: setFilterState,
        setFilters: setFilters,
        data: data,
        data1: data1,
        setTabsFlag: setTabsFlag,
        isChecked: isChecked,
        priceStyle: priceStyle,
        ratingStyle: ratingStyle,
        serviceFilter: serviceFilter,
        filters: filters,
        tabsFlag: tabsFlag,
        checkbox: checkbox,
        filterState: filterState,
        setFlag: setFlag,
        _categoriesList: _categoriesList,
        setCheckBox: setCheckBox,
      }}
    >
      <>
        <Tabs result={setCategoriesList} />

        <section className="listings">
          <div className="custom-listing-container">
            <div className="listing-content">
              <div className="row">
                <ListingPageFilters filterState={filterState} />
                <div className="col-md-6">
                  <div className="filters-listing d-flex">
                    {loader === true ? (
                      ""
                    ) : count > 0 ? (
                      <p>{count} results</p>
                    ) : (
                      ""
                    )}
                    {/* <button className="ms-auto">
                    <img src={filter} alt="..." width={14} height={14} />
                    Filters
                  </button> */}
                  </div>

                  {loader === false ? (
                    count >= 1 ? (
                      _categoriesList.length > 0 &&
                      _categoriesList?.map((val) => {
                        return (
                          <div className="list-box d-flex">
                            <div className="list-box-thumb">
                              <Link to={`/listing-detail/${val.listing_id}`}>
                                <img
                                  src={val.image_url}
                                  alt="..."
                                  width={150}
                                  height={150}
                                />
                              </Link>
                            </div>
                            <div className="list-text">
                              <div className="d-flex title-address">
                                <div className="title-review-status">
                                  <Link
                                    to={`/listing-detail/${val.listing_id}`}
                                  >
                                    <h5>{val.listing_name}</h5>
                                  </Link>
                                  <div className="review-total">
                                    <p>
                                      <img
                                        src={review}
                                        alt="..."
                                        width="14"
                                        height={14}
                                      />
                                      {val.rating} ·&nbsp;
                                      <span>{val.rating_count} reviews</span>
                                    </p>
                                    {val.menu_type.split(",").map((service) => {
                                      return (
                                        <label className="d-inline-block m-1">
                                          {service}
                                        </label>
                                      );
                                    })}
                                  </div>
                                </div>
                                <p className="ms-auto">{val.location}</p>
                              </div>
                              <div className="list-services mt-2 d-flex">
                                {val.service_name.split(",").map((service) => {
                                  return (
                                    <div className="d-flex align-items-center justify-content-center service-box">
                                      <img
                                        src={verify}
                                        alt="..."
                                        width={12}
                                        height={12}
                                      />
                                      <p>{service}</p>
                                    </div>
                                  );
                                })}
                              </div>
                              <div className="list-info d-flex">
                                <img
                                  src={message}
                                  alt="..."
                                  width="16"
                                  height="16"
                                />
                                <p>{val.description}</p>
                              </div>
                              <div className="respond-request d-flex align-items-center">
                                <div className="respond-text">
                                  <p>
                                    Responds in about
                                    <span>{val.response_time} minutes</span>
                                  </p>
                                  <span>
                                    <img
                                      className="me-1"
                                      src={graph}
                                      alt="..."
                                      width="16"
                                      height="16"
                                    />
                                    1,255 locals recently requested a quote
                                  </span>
                                </div>
                                <button className="ms-auto">
                                  Request a Quote
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <center>
                        <img
                          className="image-no-result"
                          src={NORESULT}
                          alt="..."
                        ></img>
                      </center>
                    )
                  ) : (
                    <div className="list-box d-flex justify-content-center">
                      <CircularProgress style={{ color: "#FF5348" }} />
                    </div>
                  )}
                  {count > 0 && showPagination ? (
                    <Pagination
                      count={count}
                      limit={limit}
                      handlePageClick={handlePageClick}
                      initialpage={intialpage}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-4">
                  {count > 0 ? (
                    <Map
                      category_id={id}
                      page={page}
                      limit={limit}
                      ref={childCompRef}
                      flag={flag}
                      width={"700px"}
                      result={_categoriesList}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </ListingBodyGlobals.Provider>
  );
}

export default ListingBody;
