import { useEffect, useMemo, useState, useImperativeHandle, forwardRef, useContext } from "react";
import { GoogleMap, useLoadScript, MarkerF, InfoBoxF } from "@react-google-maps/api";
import { ListingBodyGlobals } from "./ListingBody";

const Map = forwardRef((props, ref) =>  {
  const {category_id, page , limit, result, flag, width} = props
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyA_ZCbWpx7ypYl2twzzlCkPvk1pcBEp09w',
  });
  const center = useMemo(() => ({lat: 31.469320276382096,lng: 74.25963892627273}), []);
  const containerStyle = {
    // width:  '100%',
    height: width,
    // position: 'fixed'
  };
  
  const options = { closeBoxURL: '', enableEventPropagation: true };
  const [showInfoBox,setShowInfoBox] = useState(false);
  const [id,setId] = useState('');
  const [points,setPoints] = useState([]);
  const showInfoBoxFunction = () => {
    setShowInfoBox(!showInfoBox);
  }

  useEffect(()=>{
  showInfoBoxFunction(id)
  },[id])
  useEffect(()=>{
    
  },[result])
  if (!isLoaded) return <div>Loading...</div>;
  return (
    flag ? <div><GoogleMap zoom={12} center={center} mapContainerStyle={containerStyle}>
    {result?.map(e => {
      
      return <MarkerF key={e.id} position={{lat:e.latitude,lng:e.longitude }}  onMouseOver={() => {setId(e.id);showInfoBoxFunction() }} >
          {showInfoBox && id===e.id ? <InfoBoxF
            options={options}
            position={{lat:e.latitude,lng:e.longitude}}
          >
            <div style={{ backgroundColor: 'yellow', opacity: 0.75, padding: 12 }}>
              <div style={{ fontSize: 16, fontColor: `#08233B` }}>
                {e.location}
              </div>
            </div>
          </InfoBoxF> : ''}
        </MarkerF>
    })}
    
    {/* <MarkerF position={center} /> */}
  </GoogleMap></div> : ''
  );
})
export default Map








