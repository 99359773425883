import React from 'react'

function AboutUsParagraph() {
  return (
<section class="about-details">
        <div class="container">
            <div class="about-text">
                <h2>About us</h2>
                <p>At [Company Name], we are dedicated to providing [products/services] that exceed our customers' expectations. Since [Year of Establishment], we have been [brief description of company's journey]. With [number of employees/team size], we are [brief description of the team's expertise].</p>
            </div>
        </div>
    </section>
  )
}

export default AboutUsParagraph