import React, { useState } from "react";
import banner from "../imgs/banner.png";
import banner2 from "../imgs/banner2.png";
import banner3 from "../imgs/banner3.png";

import SearchBar from "./SearchBar";
import DropDownMenu from "./DropDownMenu";
import CircularProgress from "@mui/material/CircularProgress";
import Carousel from 'react-bootstrap/Carousel';

function Banner() {
  const [_categoriesList, setCategoriesList] = React.useState([]);
  const [filterState, setFilterState] = useState(false)

  React.useEffect(() => {
    data();
  }, []);

  const data = async () => {
    try {
      const res = await fetch(
        `https://test-wrangler.listing.workers.dev/api/get-categories`,
        {
          method: "GET",
        }
      );
      const result = await res.json();
      setCategoriesList(result);
      return result;
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <Carousel fade
    indicators={false}
    controls={true}
    nextIcon={<span aria-hidden="true" className="fas fa-chevron-right fa-3x customNextClass"/>}
    prevIcon={<span aria-hidden="true" className="fas fa-chevron-left fa-3x customPrevClass"/>}
    >
      <Carousel.Item interval={2000}>
      <section className="banner">
        <div
          className="banner-content"
          style={{ backgroundImage: `url(${banner})` }}
        >
          <div className="container">
            <h2>Ensure that your house is humping</h2>
            <div className="nav-banner">
              {_categoriesList !== "" ? (
                <ul className="sub-nav-banner d-flex">
                  {_categoriesList?.map((element, ind) => {
                    // Handle More when new Main category enters More always show at the end

                    return (
                      <>
                        <DropDownMenu
                          title={element?.category}
                          data_array={element?.subCategories.map((ele) => {
                            return [ele?.name, ele?.id];
                          })}
                        />
                      </>
                    );
                  })}
                </ul>
              ) : (
                <div className="sub-nav-banner d-flex">
                  <CircularProgress
                    style={{ color: "#FF5348", marginBottom: "4px" }}
                  />
                </div>
              )}

              <SearchBar filterState={filterState} />
            </div>
          </div>
        </div>
        </section>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
        <section className="banner">
        <div
          className="banner-content"
          style={{ backgroundImage: `url(${banner3})` }}
        >
          <div className="container">
            <h2 numerOfLines="2">Dial up your <br/>comfort </h2>
            <div className="nav-banner">
              {_categoriesList != "" ? (
                <ul className="sub-nav-banner d-flex">
                  {_categoriesList?.map((element, ind) => {
                    // Handle More when new Main category enters More always show at the end

                    return (
                      <>
                        <DropDownMenu
                          title={element?.category}
                          data_array={element?.subCategories.map((ele) => {
                            return [ele?.name, ele?.id];
                          })}
                        />
                      </>
                    );
                  })}
                </ul>
              ) : (
                <div className="sub-nav-banner d-flex">
                  <CircularProgress
                    style={{ color: "#FF5348", marginBottom: "4px" }}
                  />
                </div>
              )}

              <SearchBar />
            </div>
          </div>
        </div>
        </section>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
        <section className="banner">
        <div
          className="banner-content"
          style={{ backgroundImage: `url(${banner2})` }}
        >
          <div className="container">
            <h2>Your pipe dreams need to be fixed</h2>
            <div className="nav-banner">
              {_categoriesList != "" ? (
                <ul className="sub-nav-banner d-flex">
                  {_categoriesList?.map((element, ind) => {
                    // Handle More when new Main category enters More always show at the end

                    return (
                      <>
                        <DropDownMenu
                          title={element?.category}
                          data_array={element?.subCategories.map((ele) => {
                            return [ele?.name, ele?.id];
                          })}
                        />
                      </>
                    );
                  })}
                </ul>
              ) : (
                <div className="sub-nav-banner d-flex">
                  <CircularProgress
                    style={{ color: "#FF5348", marginBottom: "4px" }}
                  />
                </div>
              )}

              <SearchBar />
            </div>
          </div>
        </div>
      </section>
      </Carousel.Item>
    </Carousel>
  );
}

export default Banner;
