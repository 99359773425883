import React from 'react'
import founder from '../imgs/founder.png';
import founder2 from '../imgs/founder2.png';
import founder3 from '../imgs/founder3.png';

export default function Founder() {
  return (
    <section class="about-founders">
    <div class="container">
        <div class="about-founder-content">
            <label className='theme-label-mission' style={{width: '150px'}}>great mind</label>
            <h3 className='mt-2'>Founders</h3>
            <div class="row">
                <div class="col-md-4">
                    <div class="founder-box">
                        <img src={founder} alt="..."/>
                        <h4>Joe Gebbia</h4>
                        <p>Co-founder and Chairman of Airbnb.org</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="founder-box">
                        <img src={founder2} alt="..."/>
                        <h4>Brian Chesky</h4>
                        <p>Co-founder and Chief Executive Officer</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="founder-box">
                        <img src={founder3} alt="..."/>
                        <h4>Nathan Blecharczyk</h4>
                        <p>Co-Founder and Chief Strategy Officer</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}
