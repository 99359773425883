import React from 'react'
import PaymentMethodBody from '../components/PaymentMethodBody'

function PaymentMethod() {
  return (
    <div>
        <PaymentMethodBody/>
    </div>
  )
}

export default PaymentMethod