import React from 'react'
import DashboardListingForm from '../DashboardListingForm'
import DashboardSideNavigationBar from '../DashboardSideNavigationBar'
import ListingForm from './ListingForm'

function AddDashboardListing() {
  return (
    <div className="dashboard-wrapper d-flex">
        <DashboardSideNavigationBar />
        <DashboardListingForm />
    </div>
  )
}

export default AddDashboardListing