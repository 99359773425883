import React, { useEffect, useContext } from "react";
import SearchBar from "./SearchBar";
import { Link, useLocation, useParams } from "react-router-dom";
import { ListingBodyGlobals } from "./ListingBody";

import { useState } from "react";
function Tabs(props) {
  const {result} = props
  const useQuery = () => new URLSearchParams(useLocation().search);
  const url = useQuery();
  const query = url.get("query");
  const { id } = useParams();
  const { setFilterState,
    setPriceStyle,
    setRatingStyle,
    setisChecked,
    setCheckBox,
    filters,
    setFilters,
    filterState,
    
    } =
  useContext(ListingBodyGlobals);
  const [style, setStyle] = useState(id);

  const datatab = async () => {
    try {
      const res = await fetch(
        `https://test-wrangler.listing.workers.dev/api/get-categories`,
        {
          method: "GET",
        }
      );
      const result = await res.json();
      setCategoriesList(result);
      setisChecked([])
      return result;
    } catch (err) {
      console.log(err.message);
    }
  };

  // console.log(filters, "listingfilters");
  const [_categoriesList, setCategoriesList] = React.useState([]);
  React.useEffect(() => {
    datatab();
    
  }, []);

  const handleSelected = (e) => {
   result([])
    setCheckBox(false)

    setFilterState(false);
    setFilters({
      price: null,
      rating: null,
      service: []
    })
    setisChecked([])
    setStyle(id);
    setPriceStyle("")
    setRatingStyle("")
    

  };
  useEffect(() => {
    handleSelected();
  }, [id]);

  return (
    <>
      <section className="tags p-t-90">
        <SearchBar setCheckBox={setCheckBox} setFilterState={setFilterState} setPriceStyle={setPriceStyle} setRatingStyle={setRatingStyle} query={query} setFilters={setFilters} filterState={filterState} />
        <div
          style={{ justifyContent: "center", marginBottom: "25px" }}
          className=" d-flex custom-listing-container"
        >
          <div className="d-flex tags-content">
            {_categoriesList?.map((cat) => {
              return cat?.subCategories.map((subCat) => {
                const url = `/listing/${subCat.id}`;
                // console.log(url,"url")
                return (
                  <Link
                    to={url}
                    className={`${
                      style === `${subCat.id}` ? "active-class" : "tabs-button"
                    }`}
                    onClick={handleSelected}
                  >
                    {" "}
                    {subCat.name}
                  </Link>
                );
              });
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default Tabs;
