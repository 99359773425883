import React from 'react'
import ServicesBody from '../components/ServicesBody'
import DashboardSideNavigationBar from '../components/DashboardSideNavigationBar'

function services() {
  return (
    <div className="dashboard-wrapper d-flex">
    <DashboardSideNavigationBar/>
    <ServicesBody/>  
  </div>
  )
}

export default services