import React, { useContext, useEffect, useState } from 'react'
import red_tick from '../../imgs/icons/red_tick.png'
import  {CreateListingGlobals } from './ListingForm'
import Modal from "react-bootstrap/Modal";
import { useNavigate } from 'react-router';
import Fill from '../../imgs/icons/Fill.png'
import { MapGlobals } from '../../App';
import { CircularProgress } from "@mui/material";

function AddBusinessTermsAndConditions() {
  const {location, selectedLocation, } = useContext(MapGlobals)
  const {
    setPageNumber,
    userData, 
    listing,
    menus, 
    services,
    startTime,
    endTime,
    minPrice,
    maxPrice,
    categoryType,
    imageData,
    selected, 
    setSelected
} = useContext(CreateListingGlobals);
  const [isChecked, setIsChecked] = useState(false)
  const [modalShow, setModalShow] = React.useState(false);
  const navigate = useNavigate();
  const[loaderFlag, setLoaderFlag] = useState(false);
  const SubmitForm = async() =>{
    setLoaderFlag(true);
      const formData = new FormData();
      imageData.map((image, index)=>{
        return(
          formData.append("files", image[0])
        )
      })
      try {
        const response = await fetch(
          `https://mystic-sound-378410.de.r.appspot.com/upload`,
          {
            method: "POST",
            // headers:{'Content-Type': 'multipart/form-data'},
            body: formData,
          }
        );
        const imgs_response = await response.json();
        console.log(imgs_response);
        if(imgs_response.length> 0 ){
          try {
            const res = await fetch(
              `https://test-wrangler.listing.workers.dev/api/add-listing`,
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  sub_category_id: categoryType,
                  rating: "0.0",
                  rating_count: "0",
                  menu_type:  {menus},
                  location: location,
                  services: {"services": selected},
                  description: listing.description,
                  url: listing.url,
                  image_path: "image link here",
                  video_path: "video link here",
                  user_id: userData.id,
                  name: listing.name,
                  address: listing.address,
                  max_price: maxPrice,
                  min_price: minPrice,
                  timing: startTime + " to " + endTime,
                  start_time: startTime,
                  end_time: endTime,
                  phone_number: listing.phone_number,
                  longitude: selectedLocation.lng,
                  latitude: selectedLocation.lat,
                  listing_images: imgs_response,
                  
                }),
              }
            );
            const result = await res.json();
            console.log("api Response: ", result);
             setLoaderFlag(false)
            setModalShow(true);
          } catch (e) {
            console.log(e);
          }
        }
      } catch (e) {
        console.log(e);
      }
  
     

  }

  const handleCheck = () =>{
    if (isChecked === true){
      setIsChecked(false)
    }else setIsChecked(true)
  }

  const handleClick = (e) =>{
    e.preventDefault()
    if (isChecked === true || loaderFlag === false){
      SubmitForm();
    }
  }
  return (
<div>
      
        <div className='add-listing-form-wrapper'>
            <div className='businessFormWrapper'>
            <img src={red_tick} alt="..." width="29" height="29"/>
             <p className='formHeading'>Add Your Info</p>
             
             </div>

        <div>
        <p className='terms-and-conditions-text'>These Terms govern your access to and use of our products and services, including those offered through our websites, events, communications (e.g., emails, phone calls, and texts) and mobile applications (collectively, the “Service”). By accessing or using the Service, you are agreeing to these Terms, which form a legally binding contract with: (i) wegotyou Inc., a Delaware corporation with its headquarters in San Francisco, California, unless you are a resident of a country in the European Economic Area (the “EEA”) or Switzerland; or (ii) Yelp Ireland Ltd., a limited liability company established and resident under the laws of the Republic of Ireland, if you are a resident of a country in the EEA or Switzerland. “wegotyou” means wegotyou Inc. or wegotyou Ireland Ltd., as applicable. Do not access or use the Service if you are unwilling or unable to be bound by the Terms. For more information about our policies and instructions relating to the Service.</p>
        </div>
        <div className='businessFormWrapper checkbox-wrapper'>
            <input type="checkbox" 
            className='checkbox'
            checked={isChecked}
            onChange={handleCheck}
            />
            <p className='terms-and-conditions-checkbox-text'>
                By logging in, you agree to logo design’s Terms of Service and Privacy Policy.  
            </p>
        </div>
        <div className="login-form">
           
            <div className='uploadformlistingbtnwrapper'>
            <div className="row uploadilistingbtn">
              <div className="col-md-6">
                <button
                  className="bg-white-cusd"
                  onClick={() => setPageNumber(3)}
                >
                  BACK
                </button>

              </div>
              <div className="col-md-6">
                <button 
                className='btn btn-primary'
                onClick={handleClick}
                disabled={isChecked ? false : true}


                >
                  {loaderFlag? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50%'}}>
                      <CircularProgress style={{ color: "white",}} />
                    </div>: "SAVE AND UPLOAD"}
                  
                  </button>
              </div>
            </div>
            </div>
          </div>
          </div>
          <Modal
          className="edit-modal"
          show={modalShow}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className='d-flex justify-content-center'>
            <div className='add-listing-modal-success-background'>
                <img src={Fill} alt="..." height={19} width={26}/>
            </div>
            </div>
            <div>
              <h4 className="delete-user-text">
                Succcessfully Added!
              </h4>
            </div>
            <div className='d-flex justify-content-center'>
              <h4 className="successfullyAddedText">
              Congratulations, have a fun day! 
                            </h4>
            </div>
            <div className="login-form">
              <div className="row  modalbtngohome">
                  <button className="add-user-btn" 
                  onClick={
                    ()=>navigate("/")
                  }
                  >
                    Go To Home
                  </button>
              </div>
            </div>

          </Modal.Body>
        </Modal>

    </div>
  )
}

export default AddBusinessTermsAndConditions