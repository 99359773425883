import React from 'react'
import BusinessBanner from '../../imgs/BusinessBanner.png';

function AddListingBanner() {
    
  return (
    <>
        <section className="banner about-banner">
        <div className="banner-content about-banner-content" style={{height: '408px', backgroundImage: `url(${BusinessBanner})`}}>
            <div className="container">
                <h3 className="about-heading">List Your Business</h3>
                <p>We Connects people with great local businesses.</p>
            </div>
        </div>
    </section>
    </>
  )
}

export default AddListingBanner