import React from 'react'
import DashboardListingBody from '../components/DashboardListingBody'
import DashboardSideNavigationBar from '../components/DashboardSideNavigationBar'

function DashboardListing() {
  return (
    <div className="dashboard-wrapper d-flex">
      <DashboardSideNavigationBar/>  
      <DashboardListingBody />
    </div>
  )
}

export default DashboardListing