import React from 'react'
import { Link } from 'react-router-dom'
import missImg from '../imgs/our-mission.png'

function AboutMission() {
    return (
        <section className="our-mission">
            <div className="container">
                <div className='our-miss-content'>
                    <div className='row gx-5'>
                        <div className='col-md-6'>
                            <div className='our-miss-img'>
                                <img src={missImg} alt="..." />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='our-miss-text'>
                                <label className='theme-label-mission'>Our Mission</label>
                                <h4>How the company works</h4>
                                <p>
                                    Our mission is to [company's mission statement]. We strive to achieve this by [brief explanation of how the company works towards its mission]. Our commitment to quality is reflected in [brief explanation of company's values, work culture, or any unique selling proposition]. 
                                    <br/>
                                    Whether you're a [target audience], we've got you covered. Our [products/services] are [brief description of what makes the company's offerings unique]. We believe in [brief explanation of company's philosophy towards customer service].
                                </p>
                                <Link className='btn-nav'>Explore Our Listing</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutMission