import React from 'react'
import {Link} from 'react-router-dom';
import LOGO from '../../imgs/LOGO.png'
import facebook from '../../imgs/icons/circle-facebook.png'
import twitter from '../../imgs/icons/circle-twitter.png'
import insta from '../../imgs/icons/circle-insta.png'
function Footer() {
  return (
    <>
        <footer className="footer">
        <div className="footer-content">
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <div className="comm-footer-navs">
                            <Link to="/" className="brand-footer"><img style={{width:"130px",height:"80px", marginTop:"0px"}} src={LOGO} alt="..." /></Link>
                            <p style={{marginRight:20}}>Welcome to our website, the ultimate online destination for finding the best businesses in your area. Our mission is to connect customers with the top companies and service providers in their local community.</p>
                            <div className="footer-social-icons">
                                <Link to="#" className="ico-anchor"><img src={facebook} alt="..." width="26"/></Link>
                                <Link to="#" className="ico-anchor ms-3"><img src={twitter} alt="..." width="26"/></Link>
                                <Link to="#" className="ico-anchor ms-3"><img src={insta} alt="..." width="26"/></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="comm-footer-navs">
                            <h4>Quick Links</h4>
                            <ul>
                                <li><Link to="/about-us">About Us</Link></li>
                                <li><Link to="#">Our Blog</Link></li>
                                <li><Link to="#">Support</Link></li>
                                {/* <li><Link to="#">Terms of Service</Link></li> */}
                                {/* <li><Link to="#">Developers</Link></li> */}
                                {/* <li><Link to="#">Privacy Policy</Link></li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="comm-footer-navs">
                            <h4>Languages</h4>
                            <ul>
                                <li><Link to="#">English</Link></li>
                            </ul>
                            
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="comm-footer-navs">
                            <h4>Contact</h4>
                            <ul>
                                <li><Link to="#">333, Block J1 Johar Town, Lahore, Punjab 54000</Link></li>
                                <li><Link to="#">hr@techesthete.net</Link></li>
                                <li><Link to="#">+92-3092933334</Link></li>
                                {/* <li><Link to="#">+1-202-555-0116</Link></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="lower-footer" >
            © 2023 newlogo. All Rights Reserved
        </div>
    </footer>

    </>
  )
}

export default Footer