import React, { useEffect, useState } from 'react'
import Header from './inc/Header'
import Footer from './inc/Footer'
import { Link, useParams } from 'react-router-dom'
import Share from '../imgs/icons/share.png'
import Like from '../imgs/icons/like.png'
import Review from '../imgs/review.png'
import Detailone from '../imgs/detail-one.png'
import Detailtwo from '../imgs/detail-two.png'
import Detailthree from '../imgs/detail-three.png'
import Detailfour from '../imgs/detail-four.png'
import Detailfive from '../imgs/detail-five.png'
import Own from '../imgs/own.png'
import verifycircle from "../imgs/icons/verify-circle.png"
import Infocircle from "../imgs/icons/info-circle.png"
import Emergency from "../imgs/icons/emergency.png"
import Discount from "../imgs/icons/discount.png"
import Owned from '../imgs/icons/owned.png'
import Satisfaction from '../imgs/icons/satisfaction.png'
import Family from '../imgs/icons/family.png'
import Business from '../imgs/icons/business.png'
import Lochours from '../imgs/loc-hours.png'
import Tick from '../imgs/icons/tick.png'
import Penblack from '../imgs/icons/pen-black.png'
import Userreview from "../imgs/user-review.png"
import Curlyverify from "../imgs/icons/curly-verify.png"
import Exit from "../imgs/icons/exit.png"
import Tel from "../imgs/icons/tel.png"
import Direct from "../imgs/icons/direct.png"
import Map from './Map'
import { CircularProgress } from "@mui/material";

import { ListingBodyGlobals } from './ListingBody'
import { useContext } from 'react'

function ListingDetail() {
    const {id} = useParams()
    const [flag,setFlag] = useState(false);
    const [listing,setListing] = useState([]);
    const [listingResult, setListingResult] = useState([]);
    const [loader, setLoader] = useState(true);
    const [imageData, setImageData] = useState([]);
    const data = async() => {
        try {
            const response = await fetch(`https://test-wrangler.listing.workers.dev/api/listing-detail/${id}`,{
                    method:"GET",
                });
                const data = await response.json();
                setFlag(true);
                setListing(data.results[0]);
                setListingResult(data.results);
                setLoader(false)
               imageFunction();
        } catch(e) {
            console.log(e);
        }
    }
    const imageFunction = () => {
        console.log("Listing Detail: ", listing);
        setImageData([]);
        listing.image_url?.split(",").map((image) => {
            return (
                imageData.push(image)
            );
          })
        console.log("imageData: ", imageData);
    }
    useEffect(()=>{
        data();
    },[id])
    return (
        <section className="details-listing">
            {loader === false ? 
            <div className="container">
                <div className="details-listing-content">
                    <div className="title-share-head d-flex align-items-center">
                        <h4>{listing?.listing_name}<span className="d-block"><img src={Review} width="14" alt="..."/> {listing?.rating} · {listing?.rating_count} reviews · {listing?.location} · <span className="success-color">Open</span><span className="grey-color ms-2">{listing?.start_time} to {listing?.end_time}</span></span></h4>
                        <div className="ms-auto share-save-btn">
                            <Link><img src={Share} width="16" alt="..." className="me-2"/>Share</Link>
                            <Link><img src={Like} width="16" alt="..." className="me-2"/>Save</Link>
                        </div>
                    </div>
                    <div className="detail-collections">
                        <div className="row">
                            <div className="col-md-6">
                            {listing.image_url.split(',')[0]?
                                <div className="video-gall"><img src={listing.image_url.split(',')[0]} alt=" " style={{height: '364px', borderRadius: '10px' }}/></div>
                                 : ''}
                            </div>
                            <div className="col-md-3">
                            {listing.image_url.split(',')[1]?

                                <div className="img-gall-coll"><img src={listing.image_url.split(',')[1]} alt=" " style={{height: '176px', borderRadius: '10px' }}/></div>:''}
                                {listing.image_url.split(',')[2]?

                                <div className="img-gall-coll mb-0"><img src={listing.image_url.split(',')[2]} alt=" " style={{height: '176px', borderRadius: '10px' }}/></div>: ''}
                            </div>
                            <div className="col-md-3">
                                                            {listing.image_url.split(',')[3]?

                                <div className="img-gall-coll"><img src={listing.image_url.split(',')[3]} alt=" " style={{height: '176px', borderRadius: '10px' }}/></div>:''}
                                {listing.image_url.split(',')[4]?

                                <div className="img-gall-coll mb-0"><img src={listing.image_url.split(',')[4]} alt=" " style={{height: '176px', borderRadius: '10px' }}/></div>: ''}
                            </div>
                        </div>
                    </div>
                    <div className="detail-texts">
                        <div className="row gx-5">
                            <div className="col-md-8">
                                <div className="about-business">
                                    <h4>About the Business</h4>
                                    <span className="d-flex about-owner align-items-center"><img src={Own} width="40" height="40" alt="..."/><h6>{listing?.first_name+" "+listing?.last_name}<span className="d-block">{listing?.role_name}</span></h6></span>
                                    <p>{listing?.description}</p>
                                </div>
                                <div className="comm-border-marg service-offer">
                                    <div className="servic-text">
                                        <h5>Services Offered <span>Verified by Business</span><img src={verifycircle} alt="..." width="16" className="ms-2"/></h5>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <ul>
                                               { listing.service_name.split(",").map((service) => {
                                    return (
                                        <li>{service}</li>
                                    );
                                  })}
                                                    {/* <li>Emergency Services</li>
                                                    <li>Pipe Repair</li>
                                                    <li>Gas Line Services</li>
                                                    <li>Faucet Installation</li> */}
                                                </ul>
                                            </div>
                                            {/* <div className="col-md-5">
                                                <ul>
                                                    <li>Drain Cleaning</li>
                                                    <li>Leak Detection</li>
                                                    <li>Water Heater Installation</li>
                                                    <li>Sewer Services</li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        {/* <Link href="#" className="see-more">See More</Link> */}
                                    </div>
                                </div>
                                <div className="comm-border-marg business-offer">
                                    <div className="servic-text">
                                        <h5>Highlights from the Business<img src={Infocircle} alt="..." width="16" className="ms-2"/></h5>
                                        <div className="row gx-0">
                                            <div className="col-md-2">
                                                <div className="bus-img">
                                                    <img src={Emergency} alt="..." width="40"/>
                                                        <h6>Emergency services</h6>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="bus-img">
                                                    <img src={Discount} alt="..." width="40"/>
                                                        <h6>Discounts available</h6>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="bus-img">
                                                    <img src={Owned} alt="..." width="40"/>
                                                        <h6>Locally owned & operated</h6>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="bus-img">
                                                    <img src={Business} alt="..." width="40"/>
                                                        <h6>15 years in business</h6>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="bus-img">
                                                    <img src={Satisfaction} alt="..." width="40"/>
                                                        <h6>Satisfaction guaranteed</h6>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="bus-img">
                                                    <img src={Family} alt="..." width="40"/>
                                                        <h6>Family-owned & operated</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="comm-border-marg location-hours">
                                    <div className="servic-text">
                                        <div className="row gx-5">
                                            <div className="col-md-6">
                                                <div className="map-loc-box">
                                                    <h5>Location & Hours</h5>
                                                    {/* <img src={Lochours} alt="..."/> */}
                                                    <div style={{maxHeight: '190px', with: '400px', padding: "5px"}}>
                                                    <Map result={listingResult} flag={true} width={"200px"} />

                                                    </div>

                                                        <h6>{listing.location}</h6>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="map-hour-box">
                                                    <h6><span className="day">Mon</span><span className="24-avail">Open 24 hours</span><span className="open-now">Open Now</span></h6>
                                                    <h6><span className="day">Tue</span><span className="24-avail">Open 24 hours</span></h6>
                                                    <h6><span className="day">Wed</span><span className="24-avail">Open 24 hours</span></h6>
                                                    <h6><span className="day">Thu</span><span className="24-avail">Open 24 hours</span></h6>
                                                    <h6><span className="day">Fri</span><span className="24-avail">Open 24 hours</span></h6>
                                                    <h6><span className="day">Sat</span><span className="24-avail">Open 24 hours</span></h6>
                                                    <h6><span className="day">Sun</span><span className="24-avail">Open 24 hours</span></h6>
                                                    <Link href="#"><img src={Penblack} width="16" alt="..."/>Edit business info</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="comm-border-marg amenities">
                                    <div className="servic-text">
                                        <h5>Amenities and More</h5>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <ul>
                                                    <li><img src={verifycircle} width="24" alt="..." className="me-2"/>All staff fully vaccinated</li>
                                                    <li><img src={Tick} width="24" alt="..." className="me-2"/>Staff wears masks</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-5">
                                                <ul>
                                                    <li><img src={Tick} width="24" alt="..." className="me-2"/>Masks required</li>
                                                    <li><img src={Tick} width="24" alt="..." className="me-2"/>Accepts Credit Cards</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="request-qoute-box">
                                    <h5>Request a Quote</h5>
                                    <div className="d-flex response-time-rate">
                                        <button>Response Time <span className="d-block">10 minutes</span></button>
                                        <button className="left-bod">Response Rate <span className="d-block">95%</span></button>
                                    </div>
                                    <button className="req-btn">Request a Quote</button>
                                    <p>1344 locals recently requested a quote</p>
                                </div>
                                <div className="new-customer">
                                    <h5>$25 Off for New Customer</h5>
                                    <button className="req-btn">Get offer</button>
                                </div>
                                <div className="info-req">
                                    <ul>
                                        <li><Link href="#">{listing.url}<img src={Exit} alt="..." width="18" className="ms-auto" /></Link></li>
                                        <li><Link href="#">{listing.phone_no}<img src={Tel} alt="..." width="22" className="ms-auto" /></Link></li>
                                        <li><Link href="#">View Service Area <img src={Direct} alt="..." width="22" className="ms-auto" /></Link></li>
                                    </ul>
                                </div>
                                <div className="info-req">
                                    <ul>
                                        <li><Link href="#">$25 for New And Existing Clients!! <img src={Curlyverify} alt="..." width="22" className="ms-auto" /></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* Full Row Section */}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="star-review">
                                    <h5 className="d-flex align-items-center"><img src={Review} width="16" alt="..." className="me-2"/> 5.0 out of 5 stars from 774 reviews</h5>
                                    <div className="cusd-wrap-gap">
                                        <div className="review-detail d-flex">
                                            <h6>Cleanliness</h6>
                                            <div className="progress-bar-cusd d-flex ms-auto align-items-center">
                                                <span className="d-block"></span>
                                                <h6>5.0</h6>
                                            </div>
                                        </div>
                                        <div className="review-detail d-flex">
                                            <h6>Accuracy</h6>
                                            <div className="progress-bar-cusd d-flex ms-auto align-items-center">
                                                <span className="d-block"></span>
                                                <h6>4.9</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cusd-wrap-gap">
                                        <div className="review-detail d-flex">
                                            <h6>Communication</h6>
                                            <div className="progress-bar-cusd d-flex ms-auto align-items-center">
                                                <span className="d-block"></span>
                                                <h6>5.0</h6>
                                            </div>
                                        </div>
                                        <div className="review-detail d-flex">
                                            <h6>Location</h6>
                                            <div className="progress-bar-cusd d-flex ms-auto align-items-center">
                                                <span className="d-block"></span>
                                                <h6>4.9</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cusd-wrap-gap">
                                        <div className="review-detail d-flex">
                                            <h6>Check-in</h6>
                                            <div className="progress-bar-cusd d-flex ms-auto align-items-center">
                                                <span className="d-block"></span>
                                                <h6>5.0</h6>
                                            </div>
                                        </div>
                                        <div className="review-detail d-flex">
                                            <h6>Check-in</h6>
                                            <div className="progress-bar-cusd d-flex ms-auto align-items-center">
                                                <span className="d-block"></span>
                                                <h6>4.9</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="review-text-box">
                                            <div className="review-text-img d-flex">
                                                <img src={Userreview} alt="..." />
                                                <h5>Latisha<span className="d-block">November 2022</span></h5>
                                            </div>
                                            <p>The apartment was really spacious & clean. Amir also provided us lots of amenities. You also have access to the swimming pool & gym and all the other hotel facilities. Would recommend this place since we had a great time staying in this apartment!</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="review-text-box">
                                            <div className="review-text-img d-flex">
                                                <img src={Userreview} alt="..." />
                                                <h5>Latisha<span className="d-block">November 2022</span></h5>
                                            </div>
                                            <p>The apartment was really spacious & clean. Amir also provided us lots of amenities. You also have access to the swimming pool & gym and all the other hotel facilities. Would recommend this place since we had a great time staying in this apartment!</p>
                                        </div>
                                    </div>
                                </div>
                                <Link href="#" className="see-more btn-all-review mt-5">Show all 12 reviews</Link>
                            </div>
                        </div>
                    </div>
                    <div className="detail-slider">
                        <h4>People Also Viewed</h4>
                        <p>Slider</p>
                    </div>
                    <div className="near-me-content">
                        <div className="row">
                            <div className="col-md-4">
                                <div class="related-article">
                                    <h5>Plumbing in San Francisco</h5>
                                    <ul>
                                        <li>Get free quotes on logo design</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>: (
                    <div className="list-box d-flex justify-content-center">
                      <CircularProgress style={{ color: "#FF5348" }} />
                    </div>
                  )}
        </section>
    )
}

export default ListingDetail