import React, { useState, useContext, useRef } from "react";
import pen from "../imgs/icons/pen.png";
import trash from "../imgs/icons/trash.png";
import Search from "../components/GuiFilter";
import Graytrash from "../imgs/icons/graytrash.png";
import Graypen from "../imgs/icons/graypen.png"
import { Alert, Label } from "reactstrap";
import Modal from "react-bootstrap/Modal";
import { ServicesGlobals } from "./ServicesBody";
import Pagination from "./Paginaton";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import UserSorting from "./UserSorting";
function ServicesTable() {
  // const [categories,setCategories] = useState([]);
    const [query, setQuery] = useState("");
    const [modalShow, setModalShow] = React.useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showStatus, setStatusAlert] = useState("");
    const [showMessage, setMessageAlert] = useState("");
    const [deleteUserId, setDeleteUserId] = useState("");
    const [categoriesList, setCategoriesList] = React.useState([]);
    const [serviceInput, setServiceInput] = React.useState("");
    const [serviceType, setServiceType] = useState(0);
    const [editserviceData, setEditServiceData] = useState({});
    const [items, setItems] = useState([]);
    const [isChecked, setisChecked] = useState([]);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [showDeleteStatus, setDeleteStatusAlert] = useState(false);
    const [showDeleteMessage, setDeleteMessageAlert] = useState("");
    const [sorting, setsorting] = useState(false)

    const childCompRef = useRef();

  const {
    data,
    data1,
    services,
    initialpage,
    setInitialpage,
    setServices,
    showTableLoader,
    setTableloader,
    setFilterState,
    filterState,
  } = useContext(ServicesGlobals);
    const addService = async () => {
      const response = await fetch(
        "https://test-wrangler.listing.workers.dev/api/get-sub-categories",
        {
          method: "GET",
        }
      );
      const data = await response.json();
      console.log("Parent Categories", data.results);
      setCategoriesList(data.results);
    };

    const editCategory = async (service) => {
      // console.log("Ctegory Data: ", cat, cat.category_id === null ? 0 : cat.category_id);
      const id = service.category_id
      setEditServiceData(service);
      setServiceInput(service.service_name);
      setServiceType(id);
      setModalShow(true);
      console.log(modalShow);
      addService();
      console.log("ModalShow Value:", modalShow);
    };

    const ShowDeleteModal = (id) => {
      setShowAlert(false);
      setDeleteModalShow(true);
      setDeleteUserId(id);
    };
    const deleteService = async () => {
      try {
        const res = await fetch(
          `https://test-wrangler.listing.workers.dev/api/delete-service/${deleteUserId}`,
          {
            method: "DELETE",
          }
        );
        const result = await res.json();
        if (result.status === true) {
          setMessageAlert(result.msg);
          setStatusAlert("success");
          setShowAlert(true);
          data();
        } else {
          setMessageAlert(result.msg);
          setStatusAlert("error");
          setShowAlert(true);
        }
      } catch (err) {
        setMessageAlert(err.msg);
        setStatusAlert("error");
        setShowAlert(true);
      }
    };
    const upDateCategory = async (e) => {
      e.preventDefault();
    //   console.log("Data For Update: ", categoryInput, categoryType);
      if (serviceInput === "") {   
        setMessageAlert("Please Enter Category Name");
        setStatusAlert("error");
        setShowAlert(true);
        console.log("Alert Data: ", showMessage, showStatus);
      } else {
        try {
          const res = await fetch(
            `https://test-wrangler.listing.workers.dev/api/update-service/${editserviceData.id}`,
            {
              method: "POST",
              body: JSON.stringify({
                service_name: serviceInput,
                category_id: serviceType,
                image: null,
              }),
            }
          );
          data();
          const result = await res.json();
          console.log(result);
          setMessageAlert("Category updated successfully");
          setStatusAlert("success");
          setShowAlert(true);
          setModalShow(false);
          setShowAlert(false);
          setServiceType("");
        } catch (e) {
          console.log("error", e);
        }
      }
    };

    const handlePageClick = (data) => {
      if(!filterState){
        let currentPage = data.selected + 1;
        const commentsFormServer = data1(currentPage, limit);

        setInitialpage(data.selected);
        // console.log(initialpage)
        setItems(commentsFormServer);
        setisChecked([])

      }else{
        let currentPage = data.selected + 1;
        setInitialpage(data.selected)
        const commentsFormServer = childCompRef.current.searchByPage( `https://test-wrangler.listing.workers.dev/api/search-service?page=${currentPage}&limit=10`);
        setItems(commentsFormServer);
        setisChecked([])
      }
    };

  const handelCheckbox = (e) => {
    const { value, checked } = e.target;
    // console.log(value,'value', checked,"checked")
    if (checked) {
      setisChecked([...isChecked, value], value);
    } else {
      setisChecked(isChecked.filter((e) => e !== value));
    }
  };

    const allDelete = async () => {
      const response = await fetch(
        `https://test-wrangler.listing.workers.dev/api/bulk-delete-service`,
        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            deleteData: isChecked,
          }),
        }
      );
      const result = await response.json();
      setShowDeleteAlert(true);
      setDeleteMessageAlert(result.msg);
      console.log(result.msg);
      setDeleteStatusAlert(result.status);
      data();
      // CardsData();
      setisChecked([]);
    };

    setTimeout(() => {
      setShowDeleteAlert(false);
    }, 7000);

    const count = services?.count
    const limit = 10

  return (
    <>
      <div className="d-flex del-search">
    {
          <Button
            disabled={isChecked.length > 0 ? false : true}
            variant="contained"
            color="error"
            onClick={allDelete}
          >
            Delete
          </Button>
        }
        {showDeleteAlert ? (
          <Alert message={showDeleteMessage} type="error" />
        ) : (
          ""
        )}

    
      <Search
          placeholder={"Search Service Name"}
          url={`https://test-wrangler.listing.workers.dev/api/search-service?page=1&limit=10`}
          state={setServices}
          setLoader={setTableloader}
          setInitialpage={setInitialpage}
          data={data}
          setFilterState={setFilterState}
          ref={childCompRef}
        />
        </div>

      <div className="user-table-wrapper">
        <table className="user-tbl" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>#</th>
              <th>
                <UserSorting name="Service Name" col="service_name"
                url="https://test-wrangler.listing.workers.dev/api/order-by-services?page=1&limit=10"
                setFunction={setServices} 
                setsorting={setsorting}
                sorting={sorting}/>
              </th>
              <th>
                <UserSorting name="Category Name" col="name"
                url="https://test-wrangler.listing.workers.dev/api/order-by-services?page=1&limit=10"
                setFunction={setServices}
                setsorting={setsorting}
                sorting={sorting} />
              </th>
              <th>Quick Action</th>
            </tr>
          </thead>
          {showTableLoader === false ? (
            <tbody>
              {services.results?.map((service) => {
                return (
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        value={service.id}
                        checked={service.isChecked}
                        onChange={(e) => handelCheckbox(e)}
                      />
                    </td>
                    <td>
                      <div className="d-flex company-cell">
                        <input
                          type={"hidden"}
                          value={service.id}
                          className="hidden-user-id"
                        />
                        <p>{service.service_name}</p>
                      </div>
                    </td>
                    <td>
                      {/* <span className="customer-cell">
                      {cat.category_id === null ? (
                        <span>Parent</span>
                      ) : (
                        <span>Sub Category</span>
                      )}
                    </span> */}
                    <div className="company-cell">
                      <p>{service.category_name}</p>
                    </div>
                    </td>
                    {/* <td>
                    {cat.category_image !== null ? (
                      <div className="cat-box-img">
                        <img
                          src={cat.category_image}
                          alt="..."
                          height={30}
                          width={30}
                        />
                      </div>
                    ) : (
                      <div>?</div>
                    )}
                  </td> */}
                    <td>
                      {isChecked.length > 0 ? (
                        <span className="quick-act-ico d-flex">
                          <img
                            style={{ cursor: "pointer" }}
                            src={Graypen}
                            alt="..."
                            width="24"
                          />
                          <img
                            style={{ cursor: "pointer" }}
                            src={Graytrash}
                            alt="..."
                            width="24"
                            className="ms-2"
                          />
                        </span>
                      ) : (
                        <span className="quick-act-ico d-flex">
                          <img
                            onClick={(e) => {
                              return editCategory(service);
                            }}
                            style={{ cursor: "pointer" }}
                            src={pen}
                            alt="..."
                            width="24"
                          />
                          <img
                            onClick={() => ShowDeleteModal(service.id)}
                            style={{ cursor: "pointer" }}
                            src={trash}
                            alt="..."
                            width="24"
                            className="ms-2"
                          />
                        </span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : 
          (
            <td colspan="6" style={{ padding: "10px" }}>
              <center>
                <CircularProgress
                  style={{ color: "#FF5348", textAlign: "center" }}
                />
              </center>
            </td>
          )
          }
        </table>
      </div>

      <Modal
        className="edit-modal"
        show={modalShow}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="d-flex justify-content-center color-orange font-family-poppins">
          <img style={{ cursor: "pointer" }} src={pen} alt="..." width="24" />
          <Modal.Title id="contained-modal-title-vcenter">
            &nbsp; Edit Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="login-form">
            <div className="mb-3">
              <Label className="edit-input-label">Service Name</Label>
              <input
                type="text"
                name="Category Name"
                value={serviceInput}
                onChange={(e) => setServiceInput(e.target.value)}
                className="edit-form-control"
                placeholder="Category Name"
              />
            </div>
            <div className="mb-3">
              <Label className="edit-input-label">Category Name</Label>
              <select
                className="edit-form-control padding-rigth-15"
                value={serviceType.id}
                onChange={(e) => setServiceType(e.target.value)}
              >
                {categoriesList?.map((element) => {
                    return (editserviceData.category_id && editserviceData.category_id === element.id ?
                    <option value={element.id} selected>{element.name}</option> : <option value={element.id}>{element.name}</option> );
                })}
              </select>
            </div>
            {showAlert ? <Alert message={showMessage} type={showStatus} /> : ""}

            <div className="row">
              <div className="col-md-6">
                <button
                  variant="secondary"
                  className="bg-white-cusd"
                  onClick={(e) =>  {e.preventDefault(); setModalShow(false)}}
                >
                  Close
                </button>
              </div>
              <div className="col-md-6">
                <button variant="primary" onClick={upDateCategory}>
                  Save
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* Delete User Modal */}
      <Modal
        className="edit-modal"
        show={deleteModalShow}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="d-flex justify-content-center color-orange font-family-poppins">
          <img style={{ cursor: "pointer" }} src={trash} alt="..." width="24" />
          <Modal.Title id="contained-modal-title-vcenter">
            &nbsp; Delete User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h4 className="delete-user-text">
              Are you sure you want to delete this Category?
            </h4>
          </div>
          <form className="login-form " onSubmit={deleteService}>
            <div className="row">
              <div className="col-md-6">
                <button
                  className="bg-white-cusd"
                  onClick={(e) => { e.preventDefault(); setDeleteModalShow(false)}}
                >
                  Close
                </button>
              </div>
              <div className="col-md-6">
                <button type="submit" className="add-user-btn">
                  Delete
                </button>
              </div>
            </div>
          </form>
          {showAlert ? <Alert message={showMessage} type={showStatus} /> : ""}
        </Modal.Body>
      </Modal>
      <Pagination
        count={count}
        limit={limit}
        handlePageClick={handlePageClick}
        initialpage={initialpage}
      />
    </>
  );
}

export default ServicesTable;
