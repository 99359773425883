import React, {useState, createContext, useEffect} from 'react'
import DashboardHeader from './DashboardHeader'
import { Link } from 'react-router-dom'
import add from '../imgs/icons/add.png'
import { Label } from "reactstrap";
import Modal from "react-bootstrap/Modal";
import pen from "../imgs/icons/pen.png";
import { Alert } from 'antd';
import ServicesTable from './ServicesTable'

export const ServicesGlobals = createContext();
function ServicesBody() {
    const [modalShow, setModalShow] = React.useState(false);
    const [showAlert, setShowAlert]= useState(false);
    const [showStatus, setStatusAlert]= useState('');
    const [showMessage, setMessageAlert]= useState('');
    const [categoriesList, setCategoriesList] = React.useState([]);
    const [serviceInput, setserviceInput] = React.useState('');
    const [serviceType, setServiceType] = useState(0)
    const [services,setServices] = useState([]);
    const [initialpage, setInitialpage] = useState(0)
    const [showTableLoader , setTableloader] = useState(false)
    const [filterState, setFilterState] = useState(false)
    const [count, setcount] = useState(0)


    useEffect(() => {
      data();
      setInitialpage(0)
    }, []);
    const data = async () => {
      setTableloader(true)
      try {
        const res = await fetch(`https://test-wrangler.listing.workers.dev/api/get-services?page=1&limit=10`, {
          method: "GET",
        });
        const result = await res.json();
        // console.log(result, "Services result");
        setServices(result);
        // setcount(result.count)
        // console.log(services, "Services result");

        setTableloader(false)
        
      } catch (err) {
        console.log(err.message);
      }
    };
    const data1 = async (page, limit) => {
      setTableloader(true)
      try {
        const res = await fetch(`https://test-wrangler.listing.workers.dev/api/get-services?page=${page}&limit=${limit}`, {
          method: "GET",
        });
        const result = await res.json();
        setTableloader(false)
        setServices(result);
      } catch (err) {
        console.log(err.message);
      }
    };
    const addService = async () => {
      setShowAlert(false)
      const response = await fetch('https://test-wrangler.listing.workers.dev/api/get-sub-categories',
      {
                method:"GET",
            });
      const data = await response.json();
      setCategoriesList(data.results);
      setModalShow(true);
    };

  const createService = async(e)=>{
            e.preventDefault();
            if(serviceInput !==""){
            const response = await fetch('https://test-wrangler.listing.workers.dev/api/add-service',{
                method:"POST",
                body: JSON.stringify({
                        "service_name": serviceInput,
                        "category_id": serviceType,
                        "image": null,
                })
            });
            data();
            setMessageAlert("Category Added Successfully")
            setStatusAlert("succcess")
            setShowAlert(true)
            setModalShow(false)
          }else{
            setMessageAlert("Please Enter Category Name")
            setStatusAlert("error")
            setShowAlert(true)
          }

        // const data = await response.json();
        //     if(data.status===0)
        //     {
        //       setMessageAlert(data.msg)
        //       setStatusAlert("error")
        //       setShowAlert(true)
        //     }
        //     else if(data.status===1)
        //     {
              
        //     }
        //     else {
        //       setMessageAlert(data.msg)
        //       setStatusAlert("success")
        //       setShowAlert(true)
        //       setModalShow(false)
        //     }
}
  
  return (
    <ServicesGlobals.Provider value={{data: data , data1:data1,setTableloader:setTableloader ,setInitialpage:setInitialpage,setServices:setServices, services: services, showTableLoader:showTableLoader, initialpage:initialpage ,setFilterState:setFilterState, 
      filterState:filterState}}>
    <div class="dash-body">
        <DashboardHeader title={"Services"} />

    <div className="dash-user-content">
    <div class="d-flex align-items-center total-over-add">
        <Link onClick={addService} class="ms-auto add-user-btn"><img src={add} alt="..."/>Add Service</Link>
    </div>
    </div>

    <div class="over-tbl-content">
        <ServicesTable/>
    </div>

    <Modal
        className="edit-modal"
        show={modalShow}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="d-flex justify-content-center color-orange font-family-poppins">
          <img style={{ cursor: "pointer" }} src={pen} alt="..." width="24" />
          <Modal.Title id="contained-modal-title-vcenter">
            &nbsp; Create Service
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="login-form " onSubmit={createService}>
            <div className="mb-3">
            <Label className="edit-input-label">Service</Label>
              <input
                type="text"
                name="Category Name"
                value={serviceInput.name}
                onChange={e=>setserviceInput(e.target.value)}
                className="edit-form-control"
                placeholder="Service Name"
              />
            </div>
            <div className="mb-3">
            <Label className="edit-input-label">Category</Label>
              <select className="edit-form-control padding-rigth-15"
              value = {serviceType.id} 
              onChange={e=>setServiceType(e.target.value)}
               >
                <option value={0}>None</option>
                {categoriesList?.map((element) => {
                  return (      
                      <option value={element.id}>{element.name}</option>
                  );})}
                
              </select>
        </div>
        {showAlert? <Alert message={showMessage} type={showStatus} />: <div></div>}
            <div className="row">
              <div className="col-md-6">
                <button
                  variant="secondary"
                  className="bg-white-cusd"
                  onClick={() => setModalShow(false)}
                >
                  Close
                </button>
              </div>
              <div className="col-md-6">
                <button variant="primary">Save</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
    </ServicesGlobals.Provider>
  )
}

export default ServicesBody