import React, { useContext, useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { CreateListingGlobals } from "./ListingForm";
import addImage from "../../imgs/icons/addImage.png";
import cloud from "../../imgs/icons/cloud.png";

function UploadImage() {
  const { setPageNumber, imageData, setImageData } =
    useContext(CreateListingGlobals);
  const [singleImage, setSingleImage] = useState([]);
  const [showStatus, setStatusAlert] = useState("");
  const [showMessage, setMessageAlert] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  useEffect(()=>{
    setImageData([]);
    setImageData([]);
  },[])
  function handleChange(e) {
    imageData.push(e.target.files);
    let ImagesArray = Object.entries(e.target.files).map((e) =>
      URL.createObjectURL(e[1])
    );
    console.log(ImagesArray);
    setSingleImage([...singleImage, ...ImagesArray]);
  }

  
  const handleClick = (e) => {
    e.preventDefault();
    console.log("image data Length: ", imageData.length);
     if (imageData.length === 5) {
      setPageNumber(4);

    } else {
      setMessageAlert("You can't Upload more than and less than five images");
      setStatusAlert("error");
      setShowAlert(true);
    }
  };
  const hiddenFileInput = React.useRef(null);

  const handleBtnClick = (e) => {
    hiddenFileInput.current.click();
  };
  return (
    <div>
      <div className="add-listing-form-wrapper">
        <div className="businessFormWrapper">
          <img src={addImage} alt="..." width="29" height="29" />
          <p className="formHeading">Add Images</p>
        </div>
        <div className="mb-1">
        <span >
        Upload Images Up to 5

</span>
        </div>
        
        <div className="login-form">
          <div onClick={handleBtnClick} className="uploadimage-box">
            <img src={cloud} alt="" width="100px" height="91px" />
          </div>

          <div className="imagePreview-wrapper">
            {singleImage.length > 0 &&
              singleImage.map((item, index) => {
                return (
                  <div style={{background: ``}}>
                    <img src={item} alt="" className="imagePreview" />
                  </div>
                );
              })}
          </div>
          <input
            ref={hiddenFileInput}
            style={{ display: "none" }}
            multiple
            disabled={singleImage.length === 5}
            accept="image/*"
            type={"file"}
            name="file"
            onChange={handleChange}
          />

          {showAlert ? <Alert message="You can't Upload more than and less than five images" type="warning" /> : ""}
          <div className="addlistingbtnwrapper">
            <div className="row addlistingformBtn">
              <div className="col-md-6">
                <button
                  className="bg-white-cusd"
                  onClick={() => setPageNumber(2)}
                >
                  BACK
                </button>
              </div>
              <div className="col-md-6">
                <button className="btn btn-primary" onClick={handleClick}>
                  NEXT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadImage;
