import React from "react";
import DashboardSideNavigationBar from "./DashboardSideNavigationBar";
import EditListingBody from "./EditListingBody";

function EditListing(props) {
  
  
  return (

    <>
    <div className="dashboard-wrapper d-flex">
      <DashboardSideNavigationBar/>  
      <EditListingBody />
    </div>
    </>
  )
}

export default EditListing