import React, { useContext, useEffect, useState, } from "react";
import { Label } from "reactstrap";
import { Alert } from "antd";
import { useNavigate } from "react-router-dom";
import { ListingGlobals } from "./DashboardListingBody";
import Places from "../pages/places";
import { MapGlobals } from "../App";
import { TagsInput } from "react-tag-input-component";
import AddBusiness from "../imgs/AddBusiness.png";
import longLeftArrow from '../imgs/icons/longLeftArrow.png'
import Footer from "./inc/Footer";
import Header from "./inc/Header";
import add from "../imgs/icons/add.png";
import AddListingBanner from "./AddListing/AddListingBanner";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { CircularProgress } from "@mui/material";
import DashboardBody from "./DashboardBody";

function EditListingBody() {
    const [categories, setCategories] = useState([]);
  const [editListing, setEditListing] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showStatus, setStatusAlert] = useState("");
  const [showMessage, setMessageAlert] = useState("");
  const [getservices, setgetservices] = useState([]);
  const animatedComponents = makeAnimated();
  const [selecetedServices, setSelectedServices] = useState([]);

  const navigate = useNavigate();


  const { id } = useParams();

      const {location, setLocation, selectedLocation, setSelectedLocation} = useContext(MapGlobals);
      
      const [menus, setMenu] = useState([]);
      const [services, setServices] = useState([]); 
    

      const handleInput = (e) => {
        e.preventDefault();
        let copyEditListing = editListing;
        copyEditListing = { ...copyEditListing, [e.target.name]: e.target.value };
        setEditListing(copyEditListing);
      };
      const updateListing = async (e) => {
        console.log(selectedCategory);
        e.preventDefault();
        if (editListing.location === "") {
          setMessageAlert("Please enter Location");
          setStatusAlert("error");
          setShowAlert(true);
        } else if (editListing.listing_name === "") {
          setMessageAlert("Please enter Listing Name");
          setStatusAlert("error");
          setShowAlert(true);
        } else if (editListing.menu_type === "") {
          setMessageAlert("Please enter Comma Seprated Menu Types");
          setStatusAlert("error");
          setShowAlert(true);
        } else if (services === "") {
          setMessageAlert("Please enter Comma Seprated Services");
          setStatusAlert("error");
          setShowAlert(true);
        } else if (editListing.rating === "") {
          setMessageAlert("Please enter Rating");
          setStatusAlert("error");
          setShowAlert(true);
        } else if (editListing.rating_count === "") {
          setMessageAlert("Please enter Rating Count");
          setStatusAlert("error");
          setShowAlert(true);
        } else if (editListing.image_path === "") {
          setMessageAlert("Please enter Image Url");
          setStatusAlert("error");
          setShowAlert(true);
        } else if (editListing.video_path === "") {
          setMessageAlert("Please enter Video Url");
          setStatusAlert("error");
          setShowAlert(true);
        } else if (editListing.description === "") {
          setMessageAlert("Please enter Description");
          setStatusAlert("error");
          setShowAlert(true);
        } else if (editListing.url === "") {
          setMessageAlert("Please enter URL");
          setStatusAlert("error");
          setShowAlert(true);
        } else if (editListing.timing === "") {
          setMessageAlert("Please enter Timing");
          setStatusAlert("error");
          setShowAlert(true);
        } else if (editListing.start_time === "") {
          setMessageAlert("Please enter Startime");
          setStatusAlert("error");
          setShowAlert(true);
        } else if (editListing.end_time === "") {
          setMessageAlert("Please enter End Time");
          setStatusAlert("error");
          setShowAlert(true);
        } else if (editListing.phn_number === "") {
          setMessageAlert("Please enter Phone Number");
          setStatusAlert("error");
          setShowAlert(true);
        } else if (editListing.max_price === "") {
          setMessageAlert("Please enter Maximum Price");
          setStatusAlert("error");
          setShowAlert(true);
        } else if (editListing.min_price === "") {
          setMessageAlert("Please enter Minimum Price");
          setStatusAlert("error");
          setShowAlert(true);
        } else {
          try {
            const res = await fetch(
              `https://test-wrangler.listing.workers.dev/api/save-listing/${id}`,
              {
                method: "POST",
                body: JSON.stringify({
                  location: location,
                  listing_name: editListing.listing_name,
                  category_id: selectedCategory,
                  menu_type: {menus},
                  services: { "services": selected},
                  rating: editListing.rating,
                  rating_count: editListing.rating_count,
                  image_path: editListing.image_path,
                  video_path: editListing.video_path,
                  description: editListing.description,
                  url: editListing.url,
                  min_price: editListing.min_price,
                  max_price: editListing.max_price,
                  timing: editListing.start_time + " to " + editListing.end_time,
                  start_time: editListing.start_time,
                  end_time: editListing.end_time,
                  phn_number: editListing.phone_no,
                  longitude: selectedLocation.lat,
                  latitude: selectedLocation.lng,
                }),
              }
            );
            const result = await res.json();
            console.log(result);
            setMessageAlert("Listing updated successfully");
            setStatusAlert("success");
            setShowAlert(true);
            
          } catch (e) {
            console.log("error", e);
          }
        }
    };
    const tempArr = [];
    const [selected, setSelected] = useState(null);
      
    const handleselectChange = (selectedOption) => {
        console.log(selectedOption,'selected options');
        selectedOption.map((optionValue) => {
          tempArr.push(optionValue.value);
          setSelected(tempArr);
        })
      };
    
      const getListingData = async () => {

        console.log("listing id:", id);
        if(id!==null)
        {
        try {

          const res = await fetch(
            `https://test-wrangler.listing.workers.dev/api/view-listing/${id}`,
            {
              method: "GET",
            }
          );
          const result = await res.json();
          console.log("edit listing data: ", result);
          setEditListing(result.results[0]);
          setCategories(result.categories);
          setSelectedCategory(result.results[0].category_id);
          setLocation(result.results[0].location);
          setSelectedServices(result.selected_services)
          setSelectedLocation({lat: result.results[0].latitude, lng: result.results[0].longitude})
          setServices(result.services)
          const tempMenu = result.results[0].menu_type.split(",")
          setMenu(tempMenu);
        } catch (e) {
          console.log("error", e);
        }
      }
      };
      useEffect(()=>{
        getListingData();

    },[])

    useEffect(()=> {
    },[selecetedServices,services])

    useEffect(()=>{
        console.log('ajhjshdfeuirf v',menus);
    },[menus])


    const [val] = selecetedServices
    console.log(val,"val")

  return (
    <div className="dash-body">
    <div className="dash-user-content flex-direction-row">

          <div className="d-flex align-items-center total-over-add">
          <Link to="/admin/listing" className="anch-color d-flex justify-content-end" style={{background: 'transparent', display: 'flex', alignItems: 'center'}}>
            <img src={longLeftArrow} alt="..."/>
            <p style={{display: 'flex', alignItems: 'center', marginTop: '15px'}}>Go to Listing Page</p></Link>
          </div>
        </div>
      {location? (<div className="add-listing-form-wrapper">
      <div className="businessFormWrapper">
        <img src={AddBusiness} alt="..." width="29" height="29" />
        <p className="formHeading">List Your Business</p>
      </div>
      <form className="login-form ">
            <div className="row">
              <div className="col-md-4">
                <div className="mb-3">
                  <Label className="edit-input-label">User Name</Label>
                  <input
                    type="text"
                    name="user_name"
                    onChange={handleInput}
                    value={
                      editListing.first_name + " " + editListing.last_name
                    }
                    className="edit-form-control"
                    placeholder="User Name"
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                <Label className="edit-input-label">Listing Name</Label>
                  <input
                    type="text"
                    name="listing_name"
                    onChange={handleInput}
                    value={editListing.listing_name}
                    className="edit-form-control"
                    placeholder="Listing Name"
                  />
                  
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                <Label className="edit-input-label">Phone Number</Label>
                  <input
                    type="tel"
                    name="phn_number"
                    onChange={handleInput}
                    value={editListing.phone_no}
                    className="edit-form-control"
                    placeholder="Phone Number"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="mb-3">
                  <Label className="edit-input-label">Category Name</Label>
                  <select
                    className="edit-form-control padding-rigth-15"
                    onChange={(e) => {
                      setSelectedCategory(e.target.value);
                    }}
                  >
                    {categories.results?.map((element) => {
                      return editListing.category_id &&
                        editListing.category_id === element.id ? (
                        <option value={editListing.category_id} selected>
                          {editListing.sub_cat_name}
                        </option>
                      ) : (
                        <option value={element.id}>{element.name}</option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <Label className="edit-input-label">Menu</Label>
                  <TagsInput
                    value={menus}
                    onChange={setMenu}
                    name="Menu"
                    placeHolder="Add Menu"
                    />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <Label className="edit-input-label">Services</Label>
                  {services.length > 0  && selecetedServices? 
                  
                  <Select
                        isMulti
                        defaultValue={selecetedServices}
                        options={services}
                        closeMenuOnSelect={false}
                        onChange={handleselectChange}
                        autoFocus={true}
                    />
                    :
                    ""
                }
                  
                  {/* <TagsInput
              value={services}
              onChange={setServices}
              name="services"
              placeHolder="Add Services"
            /> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="mb-3">
                  <Label className="edit-input-label">Rating</Label>
                  <input
                    type="text"
                    name="rating"
                    onChange={handleInput}
                    value={editListing.rating}
                    className="edit-form-control"
                    placeholder="Rating"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <Label className="edit-input-label">Rating Count</Label>
                  <input
                    type="text"
                    name="rating_count"
                    onChange={handleInput}
                    value={editListing.rating_count}
                    className="edit-form-control"
                    placeholder="Rating count"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <Label className="edit-input-label">Image</Label>
                  <input
                    type="text"
                    name="image_path"
                    onChange={handleInput}
                    value={editListing.image_path}
                    className="edit-form-control"
                    placeholder="Image Link"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="mb-3">
                  <Label className="edit-input-label">Start Time</Label>
                  <input
                    type="time"
                    name="start_time"
                    onChange={handleInput}
                    value={editListing.start_time}
                    className="edit-form-control"
                    placeholder="Start Time"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <Label className="edit-input-label">End Time</Label>
                  <input
                    type="time"
                    name="end_time"
                    onChange={handleInput}
                    value={editListing.end_time}
                    className="edit-form-control"
                    placeholder="Rating count"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <Label className="edit-input-label">Minimum Price</Label>
                  <input
                    type="number"
                    name="min_price"
                    onChange={handleInput}
                    value={editListing.min_price}
                    className="edit-form-control"
                    placeholder="Minimum Price"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="mb-3">
                  <Label className="edit-input-label">Maximum Price</Label>
                  <input
                    type="number"
                    name="max_price"
                    onChange={handleInput}
                    value={editListing.max_price}
                    className="edit-form-control"
                    placeholder="Maximum Price"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <Label className="edit-input-label">Video</Label>
                  <input
                    type="text"
                    name="video_path"
                    onChange={handleInput}
                    value={editListing.video_path}
                    className="edit-form-control"
                    placeholder="Video Link"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <Label className="edit-input-label">URL</Label>
                  <input
                    type="text"
                    name="url"
                    onChange={handleInput}
                    value={editListing.url}
                    className="edit-form-control"
                    placeholder="URL"
                  />
                </div>
              </div>
                <div className="mb-3">
                  {location? <Places /> : ""}
                  
                </div>
            </div>
            <div className="mb-3">
              <Label className="edit-input-label">Description</Label>
              <textarea
                type="text"
                name="description"
                value={editListing.description}
                onChange={handleInput}
                className="about-input"
                placeholder="Description"
                rows={8}
                cols={40}
              />
            </div>
            {showAlert ? (
              <Alert message={showMessage} type={showStatus} />
            ) : (
              <div></div>
            )}
            <div className="row">
              <div className="col-md-6">
                <button
                  className="bg-white-cusd"
                  onClick={(e) => {
                  //   e.preventDefault();
                  //   setModalShow(false);
                  }}
                >
                  Close
                </button>
              </div>
              <div className="col-md-6">
                <button onClick={updateListing}>Save</button>
              </div>
            </div>
          </form>
      </div>): (
                  <div className="list-box d-flex justify-content-center">
                    <CircularProgress style={{ color: "#FF5348" }} />
                  </div>
                )}
    </div>
  )
}

export default EditListingBody