import React from 'react'
import AboutBanner from '../imgs/AboutBanner.jpg';

function AboutUsBanner() {
    
  return (
    <>
        <section className="banner about-banner">
        <div className="banner-content about-banner-content" style={{backgroundImage: `url(${AboutBanner})`}}>
            <div className="container">
                <h3 className="about-heading">About Us</h3>
                <p>We Connects people with great local businesses</p>
            </div>
        </div>
    </section>
    </>
  )
}

export default AboutUsBanner