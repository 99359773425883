import React from 'react'
import Footer from '../components/inc/Footer'
import Header from '../components/inc/Header'
import ListingBody from '../components/ListingBody'
import Tabs from '../components/Tabs'
import { useParams, useLocation } from "react-router-dom";

function ListingPage() {
const {id } = useParams();
  return (
    <>
        <Header/>
        {/* <center><Tabs /></center> */}
        <ListingBody id= {id} />
        <Footer/>
    </>
  )
}
export default ListingPage