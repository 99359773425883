import {useMemo, useContext } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import { Label } from "reactstrap";
import "@reach/combobox/styles.css";
import { MapGlobals } from "../App";
import { useEffect } from "react";
import { add } from "lodash";

export default function Places() {
    
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyA_ZCbWpx7ypYl2twzzlCkPvk1pcBEp09w',
    libraries: ["places"],
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <Map />;
}

    function Map() {
    const {
        setSelectedLocation,
        selectedLocation,
       
    } = useContext(MapGlobals); 
  
  const center = useMemo(() => ({ lat: 31.47121326511383, lng: 74.26992288084759}), []);
  //const [selected, setSelected] = useState(null);
  const containerStyle = {
    width: '100%',
    height: '200px',
    position: 'relative',
    display: 'flex',
    borderRadius: '10px'

  };
  const saveDragInfo = (MapMouseEvent) => {
    console.log(MapMouseEvent);
  }

    // useEffect(() => {
    // }, [selectedLocation])
  return (
    <>
      <div className="places-container">
        <PlacesAutocomplete setSelected={setSelectedLocation} selected={selectedLocation} />
      </div>

      <GoogleMap
        zoom={15}
        center={selectedLocation? selectedLocation :center}
        mapContainerStyle={containerStyle}
      >
        {selectedLocation && <MarkerF position={selectedLocation} draggable={true} onDrag={saveDragInfo} />}
      </GoogleMap>
    </>
  );
}

const PlacesAutocomplete = () => {
    const {
        setSelectedLocation,
        selectedLocation,
        location , 
        setLocation,
    } = useContext(MapGlobals);
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  // useEffect(()=>{
  //   setValue(location);
  // },[location])
  const handleSelect = async (address) => {
    setLocation(address);
    setValue(address, false);
    clearSuggestions();
    const results = await getGeocode({ address });
    const { lat, lng } = getLatLng(results[0]);
    // console.log(lat, lng, "lat,lng");
    setSelectedLocation({ lat, lng });
    // console.log(selectedLocation);
    // console.log(location)
  };

  return (
    <Combobox onSelect={handleSelect}>
        <Label className="edit-input-label">Location</Label>
      <ComboboxInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={!ready}
        className="edit-form-control cold-md-12 mb-3"
        placeholder="Add Business Address"
      />
      <ComboboxPopover >
        <ComboboxList className="">
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};