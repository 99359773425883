import React from 'react'
import LOGO from '../../imgs/LOGO.png'
import {Link, useNavigate } from 'react-router-dom';
import user from "../../imgs/user.png";

function Header() {     
    const navigate  = useNavigate()
    const token = localStorage.getItem('token')
    const username = localStorage.getItem('username')
    const userrole = localStorage.getItem('Status')
    const clearToken = () => {
        localStorage.clear()
        navigate('/')
    }
    console.log("Status: ", userrole);

  return (
    <>
        <header className="header">
            <div className="container">
                <nav className="navbar navbar-expand-lg">
                    <Link className="navbar-brand" to="/">
                        <img 
                            src={LOGO} 
                            alt="Logo"
                            width={216}
                            style={{width:100}}
                    />
                    </Link> 
                    <button aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" className="navbar-toggler" data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse" type="button"><span className="navbar-toggler-icon"></span></button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            {/* <li className="nav-item">
                                <Link className="nav-link" to="#">For Business</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="#">Write a Review</Link>
                            </li> */}
                        </ul>
                    {
                        !token ? <form className="d-flex">
                        <Link to="/login" className="btn-nav-login">Login</Link>
                        <Link to="/signup" className="btn-nav">Sign Up</Link>
                    </form> : <div class="btn-group signout-user ">
                    {userrole === '3'? 
                    <Link to="/create-listing">
                    <button className="btn-nav-addlisting mt-2">Add your Listing</button>
                    </Link>
                     :
                     <Link to="/register-business">
                     <button className="btn-nav-addlisting mt-2">Register Business</button>
                     </Link>
                    }
                    
                    <button  onClick={clearToken} className="btn-nav-login mt-2">Logout</button>
                    
                    <img width="50px"  className='mt-1' src={user}  alt="..." />
                    <p className='header-username'>{username}</p>

                
                </div>
                    }
                        
                    </div>
                </nav>
            </div>
        </header>
    </>
  )
}

export default Header