import React, { createContext, useState } from "react";
import Homepage from "./pages/homepage";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import ListingPage from "./pages/listing";
import Login from "./pages/login";
import Signup from "./pages/signup";
import AboutUsPage from "./pages/AboutUsPage";
import Admin from "./pages/Admin";
import Dashboard from "./pages/Dashboard";
import ListingViewDetail from "./pages/listiingdetail";
import Categories from "./pages/categories";
import Services from "./pages/services"
import DashboardListing from "./pages/DashboardListing";
import Protected from "./components/Protected";
import ListingForm from "./components/AddListing/ListingForm";
import Places from "./pages/places";
import DashboardListingForm from "./components/DashboardListingForm";
import EditListing from "./components/EditListing";
import AddDashboardListing from "./components/AddListing/AddDashboardListing";
import PaymentMethod from "./pages/PaymentMethod";
export const MapGlobals = createContext();

function App() {
  const [selectedLocation, setSelectedLocation] = useState("");
  const [location, setLocation] = useState("");
  const [editListingId, setEditListingId] = useState("");

  return (
    <MapGlobals.Provider
      value={{
        location: location,
        setLocation: setLocation,
        selectedLocation: selectedLocation,
        setSelectedLocation: setSelectedLocation,
        editListingId, setEditListingId
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/home" element={<Homepage />} />
          <Route path="/listing/:id?" element={<ListingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/admin" element={<Admin />} />
          <Route
            path="/admin/user"
            element={<Protected Component={Dashboard} />}
          />
          <Route path="/listing-detail/:id" element={<ListingViewDetail />} />
          <Route
            path="/admin/listing"
            element={<Protected Component={DashboardListing} />}
          />
          <Route
            path="/admin/categories"
            element={<Protected Component={Categories} />}
          />
          <Route
            path="/admin/services"
            element={<Protected Component={Services} />}
          />
          <Route path="/create-listing" element={<ListingForm />} />
          <Route path="/register-business" element={<PaymentMethod />} />

          <Route path="/places" element={<Places />} />
          <Route
            path="/admin/listingForm"
            element={<Protected Component={AddDashboardListing} />}
          />
            <Route
            path="/admin/editListing/:id"
            element={<Protected Component={EditListing} />}
          />
        </Routes>
      </BrowserRouter>
    </MapGlobals.Provider>
  );
}
export default App;
