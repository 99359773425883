import React, { useState } from 'react'
import login from '../imgs/login.png';
import {Link, useNavigate } from 'react-router-dom';
import { Alert } from 'antd';

function AdminLogin() {
    const navigate = useNavigate();
    const [showAlert, setShowAlert]= useState(false);
    const [showStatus, setStatusAlert]= useState('');
    const [showMessage, setMessageAlert]= useState('');
    const [user,setUser] = useState({
        email: "",
        password: ""
    });
    const handleSubmit = async(e)=>{
        e.preventDefault();
        localStorage.clear()
            const response = await fetch('https://test-wrangler.listing.workers.dev/auth/login',{
                method:"POST",
                headers: {'Content-Type':'application/json'},
                body: JSON.stringify({
                        "email": user.email,
                        "password": user.password
                })
            });
            const data = await response.json();
            if(data.status===0)
            {
                setMessageAlert(data.msg)
                setStatusAlert("error")
                setShowAlert(true)
            }
            else if(data.status===1){
                setMessageAlert(data.msg)
                setStatusAlert("error")
                setShowAlert(true)
            }
            else {
                localStorage.setItem("token",data.token);
                localStorage.setItem("username",data.name);
                //swal("Success", data.msg, "success");
                if(data.user_role==="1")
                {
                    navigate('/admin/user')
                }
                else{
                    setUser(
                        {
                            email: '',
                            password: '',
                        }
                    )
                    setMessageAlert("You are not authorized to acces this page")
                    setStatusAlert("warning")
                    setShowAlert(true)
                }
            }
        }
        
    const handleChange = (e)=>{
        e.persist();
        setUser({...user,[e.target.name]:e.target.value});
    }
    return (
        <>
            <section className="login">
                <div className="container">
                    <div className="login-content">
                        <div className="row gx-5">
                            <div className="col-md-5">
                                <div className="login-box center">
                                    <h5 className="orange">Welcome to Admin Panel</h5>
                                    {/* <p>New to LOGO DESIGN ? <Link to="/signup" className="anch-color">Sign Up</Link></p>
                                    <span className=" d-block">
                                        By logging in, you agree to logo design’s <Link to="#" className="anch-color">Terms of Service</Link> and <Link to="#" className="anch-color">Privacy Policy</Link>.
                                    </span>  */}
                                    <form onSubmit={handleSubmit} className="login-form" action="">
                                           <div className="mb-3">
                                            <input type="email" name='email' onChange={handleChange} value={user.email} className="form-control" placeholder="Email"/>
                                        </div>
                                        <div className="mb-3">
                                            <input type="password" name='password' onChange={handleChange} value={user.password} className="form-control" placeholder="Password"/>
                                        </div>
                                        {/* <Link to="#" className="anch-color d-flex justify-content-end">Forgot password?</Link> */}
                                        <button type="submit" className="btn btn-primary">Login</button>
                                        {showAlert? <Alert message={showMessage} type={showStatus} />: <div></div>}

                                        <span className="d-flex justify-content-end"><Link to="/" className="anch-color">Go back to Site</Link></span>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="login-img"><img src={login} alt="..." /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AdminLogin