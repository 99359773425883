import React, {useContext, useEffect, useState} from 'react'
import PersonalInfo from '../../imgs/icons/PersonalInfo.png'
import addInfo from '../../imgs/icons/addInfo.png'
import addImages from '../../imgs/icons/addImages.png'
import done from '../../imgs/icons/done.png'
import  {CreateListingGlobals } from './ListingForm'

function ProgressBar() {
    const {pagenumber} = useContext(CreateListingGlobals);  
    const [pagetwoFlag, settwoPage] = useState(false);
    const [pagethreeFlag, setThreePage] = useState(false);
    const [pageFourFlag, setFourPage] = useState(false);
    const [formDone, setformDone] = useState(false);

    useEffect(()=>{
        switch(pagenumber) {
            case 2: 
                    settwoPage(true);
                    break;
            case 3: 
                    settwoPage(true);
                    setThreePage(true);
                    break;
            case 4: 
                    setFourPage(true); 
                    settwoPage(true); 
                    setThreePage(true);
                    break;
            case 5: 
                    setFourPage(true); 
                    settwoPage(true); 
                    setThreePage(true);
                    setformDone(true); break;
            default: break;
        }  
    },[pagenumber])

  return (
    <div className='progress-bar-wrapper'>
        <div className='progress-bar-container'>
        <div className='progress-bar-box'>
            
            <div className='progress-status-circle'>
                <img src={PersonalInfo} alt="." className='progress-bar-img'/>
            </div>
            {/* <h2 className='progress-step-name'>Personal Info</h2> */}

        <div className={pagetwoFlag===true? 'progress-line': 'proggress-line-inactive'}/>

            <div className={pagethreeFlag? 'progress-status-circle': 'progress-status-circle-inactive'}>
                <img src={addInfo} alt="." className='progress-bar-img'/>
            </div>
            {/* <h2 className='progress-step-name'>Business Info</h2> */}

        <div className={pagethreeFlag? 'progress-line': 'proggress-line-inactive'}/>


            <div className={pageFourFlag? 'progress-status-circle': 'progress-status-circle-inactive'}>
                <img src={addImages} alt="." className='progress-bar-img'/>
            </div>
            {/* <h2 className='progress-step-name'>Add Images</h2> */}

        <div className={pageFourFlag? 'progress-line': 'proggress-line-inactive'}/>


            <div className={formDone? 'progress-status-circle': 'progress-status-circle-inactive'}>
                <img src={done} alt="." className='progress-bar-img'/>
            </div>
            {/* <h2 className='progress-step-name'>Almost Done</h2> */}




        </div>
        <div className='progress-text-box'>
        <h2 className='progress-step-name'>Personal Info</h2>
        <h2 className={pagetwoFlag?'progress-step-name': 'progress-step-name-inactive'}>Business Info</h2>
        <h2 className={pagethreeFlag?'progress-step-name': 'progress-step-name-inactive'}>Add Images</h2>
        <h2 className={formDone?'progress-step-name': 'progress-step-name-inactive'}>Almost Done</h2>
        </div>
        </div>
    </div>
  )
}

export default ProgressBar