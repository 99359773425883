import React, {useEffect} from 'react'
import AdminLogin from '../components/AdminLogin'
import {useNavigate } from 'react-router-dom';

export default function Admin() {
    const navigate = useNavigate();
    useEffect(()=>{
        let login = localStorage.getItem('token');
        if (login !== null){
            navigate("/admin/user")
        }
    });
        return (
          <AdminLogin/> 
        );
};