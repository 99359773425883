import { color } from "@mui/system";
import React, { useState, useEffect, useContext } from "react";
import { ListingBodyGlobals } from "./ListingBody";

const ListingPageFilters = ({ filterState }) => {
  const {
    checkbox,
    setCheckBox,
    setFilterState,
    filters,
    setFilters,
    data,
    data1,
    serviceFilter,
    priceStyle,
    setPriceStyle,
    setRatingStyle,
    ratingStyle,
    isChecked,
    setisChecked,
  } = useContext(ListingBodyGlobals);
  const [val, setVal] = useState(null);
  const [activeFilter, setactivefilter] = useState([]);
  const [appliedfilters, setappliedfilters] = useState({
    value: "",
    type: "",
  });

  // console.log(activeFilter,"active");

  const handleClick = async (e) => {
    if (e.target.getAttribute("data-value") === "1") {
      setFilters({ ...filters, price: e.target.getAttribute("value") });
      setPriceStyle(e.target.getAttribute("value"));

      setappliedfilters({
        ...appliedfilters,
        value: e.target.innerHTML,
        type: e.target.getAttribute("data-value"),
      });
      setactivefilter([
        ...activeFilter,
        {
          ...appliedfilters,
          value: e.target.innerHTML,
          type: e.target.getAttribute("data-value"),
        },
      ]);
    } else if (e.target.getAttribute("data-value") === "2") {
      setFilters({ ...filters, rating: e.target.getAttribute("value") });
      setRatingStyle(e.target.getAttribute("value"));
      setappliedfilters({
        ...appliedfilters,
        value: e.target.innerHTML,
        type: e.target.getAttribute("data-value"),
      });
      setactivefilter([
        ...activeFilter,
        {
          ...appliedfilters,
          value: e.target.innerHTML,
          type: e.target.getAttribute("data-value"),
        },
      ]);
    }
    setFilterState(true);
  };

  const handelCheckbox = (e) => {
    setFilterState(true);
    const { value, checked } = e.target;
    if (checked) {
      setCheckBox(true);
      setVal(value);
      setisChecked([...isChecked, value], value);
      setFilters({ ...filters, service: [...isChecked, value] });
      setappliedfilters({
        ...appliedfilters,
        value: [e.target.name],
        type: "3",
      });
      setactivefilter([
        ...activeFilter,
        { ...appliedfilters, value: e.target.name, type: 3 },
      ]);
    } else {
      setisChecked(isChecked.filter((e) => e !== value));
    }
  };

  const clearAllHandle = () => {
    setFilterState(false);
    setappliedfilters({});
    setRatingStyle("");
    setPriceStyle("");
    setactivefilter([]);
    setFilters({
      price: null,
      rating: null,
      service: [],
    });
  };
  useEffect(() => {
    if (Object.keys(appliedfilters).length === 0) {
      data();
    }
  }, [appliedfilters]);

  useEffect(() => {
    // clearAllHandle()
    if (filterState) {
      data();
    }
  }, [filters]);

  // console.log(activeFilter, "active_filter");
  console.log(appliedfilters, "applied filter");
  console.log(activeFilter, "active filter");

  return (
    <div className="col-md-2">
      <h6>Filters</h6>
      <div className="bottom-line">
        {filterState === true ? (
          <>
            <span onClick={clearAllHandle} className="btn">
              Clear All
            </span>
            <div>
              <ul style={{ textAlign: "left" }}>
                {activeFilter.map((filter) => {
                  // console.log(filter, "filter");
                  // return <li>{handleFilter(filter)}</li>;
                })}
              </ul>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="bottom-line">
        <div className="price">
          <p>Price</p>
          <div className="container-fluid">
            <div style={{ flexDirection: "row", display: "flex" }}>
              <div
                data-value="1"
                value="9"
                onClick={handleClick}
                className={`classforborder ${
                  priceStyle === "9" ? "active-filter-class" : "price-range btn"
                }`}
                style={{
                  borderRadius: "25px 0px 0px 25px",
                  width: "auto",
                  borderBottom: "none",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px 10px",
                  color: "#6e7072",
                }}
              >
                $
              </div>
              <div
                data-value="1"
                onClick={handleClick}
                value="99"
                className={`classforborder ${
                  priceStyle === "99"
                    ? "active-filter-class"
                    : "price-range btn"
                }`}
                style={{
                  borderRadius: "0",
                  width: "auto",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px 10px",
                  color: "#6e7072",
                }}
              >
                $$
              </div>
              <div
                data-value="1"
                onClick={handleClick}
                value="999"
                className={`classforborder  ${
                  priceStyle === "999" ? "active-filter-class" : "btn"
                }`}
                style={{
                  borderRadius: "0",
                  width: "auto",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px 10px",
                  color: "#6e7072",
                }}
              >
                $$$
              </div>
              <div
                data-value="1"
                onClick={handleClick}
                value="9999"
                className={`${
                  priceStyle === "9999" ? "active-filter-class" : "btn"
                }`}
                style={{
                  borderRadius: "0px 25px 25px 0",
                  width: "auto",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px 10px",
                  color: "#6e7072",
                }}
              >
                $$$$
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-line">
        <div className="price" style={{ marginTop: "15px" }}>
          <p>Rating</p>
          <div className="container-fluid">
            <div className="row" style={{ gap: "5px" }}>
              <div
                data-value="2"
                value="1"
                onClick={handleClick}
                className={` col-sm-3 ${
                  ratingStyle === "1"
                    ? "active-filter-class"
                    : "price-range btn"
                }`}
                style={{ borderRadius: "25px", width: "auto", color: "orange" }}
              >
                ★
              </div>
              <div
                data-value="2"
                value="2"
                onClick={handleClick}
                className={` col-sm-3 ${
                  ratingStyle === "2" ? "active-filter-class" : "btn"
                }`}
                style={{ borderRadius: "25px", width: "auto", color: "orange" }}
              >
                ★★
              </div>
              <div
                data-value="2"
                value="3"
                onClick={handleClick}
                className={` ${
                  ratingStyle === "3" ? "active-filter-class" : "btn"
                }`}
                style={{ borderRadius: "25px", width: "auto", color: "orange" }}
              >
                ★★★
              </div>
              <div
                data-value="2"
                value="4"
                onClick={handleClick}
                className={` ${
                  ratingStyle === "4" ? "active-filter-class" : "btn"
                }`}
                style={{ borderRadius: "25px", width: "auto", color: "orange" }}
              >
                ★★★★
              </div>

              <div
                data-value="2"
                value="5"
                onClick={handleClick}
                className={` ${
                  ratingStyle === "5" ? "active-filter-class" : "btn"
                }`}
                style={{ borderRadius: "25px", width: "auto", color: "orange" }}
              >
                ★★★★★
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-line">
        <div className="price" style={{ marginTop: "15px" }}>
          <p>Services</p>
          <div className="container-fluid">
            {serviceFilter.map((service) => {
              return (
                <div
                  className="checbox-input"
                  style={{ gap: "5px", display: "flex" }}
                >
                  <input
                    type="checkbox"
                    value={service.id}
                    // autocomplete='off'
                    checked={checkbox && val == service?.id ? true : false}
                    name={service.service_name}
                    onChange={(e) => {
                      handelCheckbox(e);
                    }}
                  />
                  <label for={service.id}>{service.service_name}</label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingPageFilters;
