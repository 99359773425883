import React, { useEffect, useState, createContext, useContext } from "react";
import DashboardHeader from "./DashboardHeader";
import { Link } from "react-router-dom";
import add from "../imgs/icons/add.png";
import ListingTable from "./ListingTable";
import pen from "../imgs/icons/pen.png";
import Modal from "react-bootstrap/Modal";
import { Label } from "reactstrap";
import { Alert } from "antd";
import DashboardListingCards from "./DasboardListingCards";
import Places from "../pages/places";
import Header from "./inc/Header";
import AddListingBanner from "./AddListing/AddListingBanner";
import AddBusiness from "../imgs/AddBusiness.png";
import longLeftArrow from "../imgs/icons/longLeftArrow.png";
import Footer from "./inc/Footer";
import { MapGlobals } from "../App";
import { TagsInput } from "react-tag-input-component";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import addImage from "../imgs/icons/addImage.png";
import cloud from "../imgs/icons/cloud.png";


export const ListingGlobals = createContext();
function DashboardListingForm() {
  const { location, setLocation, selectedLocation, setSelectedLocation } =
    useContext(MapGlobals);
    const [singleImage, setSingleImage] = useState([]);

  const [modalShow, setModalShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [users, setUsers] = useState([]);
  const [showStatus, setStatusAlert] = useState("");
  const [showMessage, setMessageAlert] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  // const [flag, setFlag] = useState(false);
  const [alllisting, setAllListing] = useState([]);
  const [loader, setLoader] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totallisting, setTotalListing] = useState(0);
  const [initialpage, setInitialpage] = useState(0);
  const [showTableLoader, setTableloader] = useState(false);
  const [filterState, setFilterState] = useState(false);
  const [menus, setMenu] = useState([]);
  const [services, setServices] = useState([]);
  const [getservices, setgetservices] = useState([]);
  const animatedComponents = makeAnimated();
  const [imageData, setImageData] = useState([]);

  const tempArr = [];
  const [selected, setSelected] = useState(null);
  const handleselectChange = (selectedOption) => {
    selectedOption.map((optionValue) => {
      tempArr.push(optionValue.value);
      setSelected(tempArr);
    });
  };
  useEffect(() => {
    console.log(selected, "selected");
  }, [selected]);

  const [listing, setListing] = useState({
    user_id: "",
    location: "",
    category_id: "",
    menu_type: [],
    services: [],
    rating: "",
    rating_count: "",
    image_link: "",
    video_link: "",
    url: "",
    start_time: "",
    end_time: "",
    max_price: "",
    min_price: "",
    phn_number: "",
    description: "",
    name: "",
    address: "",
  });

  useEffect(() => {
    getCategories();
    setSelectedLocation("");
    setLocation("");
    // setSelected()
  }, []);

  const getCategories = async (e) => {
    try {
      const res = await fetch(
        `https://test-wrangler.listing.workers.dev/api/get-sub-categories`,
        {
          method: "GET",
        }
      );
      const result = await res.json();
      const res2 = await fetch(
        `https://test-wrangler.listing.workers.dev/api/get-all-listing-user`,
        {
          method: "GET",
        }
      );
      const result2 = await res2.json();
      setCategories(result.results);
      setUsers(result2.results);
      setSelectedUser(result2.results[0].id);
      setModalShow(true);
    } catch (e) {
      console.log(e);
    }
    setModalShow(true);
  };

  const addListing = async (e) => {
    e.preventDefault();
    if (listing.name === "") {
      setMessageAlert("Please enter Listing Name");
      setStatusAlert("error");
      setShowAlert(true);
    } else if (listing.menu_type === "") {
      setMessageAlert("Please enter Comma Seprated Menu Types");
      setStatusAlert("error");
      setShowAlert(true);
    } else if (listing.services === "") {
      setMessageAlert("Please enter Comma Seprated Services");
      setStatusAlert("error");
      setShowAlert(true);
    } else if (listing.rating === "") {
      setMessageAlert("Please enter Rating");
      setStatusAlert("error");
      setShowAlert(true);
    } else if (listing.rating_count === "") {
      setMessageAlert("Please enter Rating Count");
      setStatusAlert("error");
      setShowAlert(true);
    } else if (listing.image_path === "") {
      setMessageAlert("Please enter Image Url");
      setStatusAlert("error");
      setShowAlert(true);
    } else if (listing.video_path === "") {
      setMessageAlert("Please enter Video Url");
      setStatusAlert("error");
      setShowAlert(true);
    } else if (listing.description === "") {
      setMessageAlert("Please enter Description");
      setStatusAlert("error");
      setShowAlert(true);
    } else if (listing.url === "") {
      setMessageAlert("Please enter URL");
      setStatusAlert("error");
      setShowAlert(true);
    } else if (listing.timing === "") {
      setMessageAlert("Please enter Timing");
      setStatusAlert("error");
      setShowAlert(true);
    } else if (listing.min_price === "") {
      setMessageAlert("Please enter Minimum Price");
      setStatusAlert("error");
      setShowAlert(true);
    } else if (listing.max_price === "") {
      setMessageAlert("Please enter Maximum Price");
      setStatusAlert("error");
      setShowAlert(true);
    } else if (listing.phn_number === "") {
      setMessageAlert("Please enter Phone Number");
      setStatusAlert("error");
      setShowAlert(true);
    } else if (listing.start_time === "") {
      setMessageAlert("Please enter Start Time");
      setStatusAlert("error");
      setShowAlert(true);
    } else if (listing.end_time === "") {
      setMessageAlert("Please enter End Time");
      setStatusAlert("error");
      setShowAlert(true);
    } else if (listing.min_price >= listing.max_price) {
      setMessageAlert(
        "Menus maximum price cannot be less than or equal to minimum price. "
      );
      setStatusAlert("error");
      setShowAlert(true);
    } else {
      const formData = new FormData();
      imageData.map((image)=>{
        return(
          formData.append("files", image[0])
        )
      })
      try {
        const response = await fetch(
          `https://mystic-sound-378410.de.r.appspot.com/upload`,
          {
            method: "POST",
            // headers:{'Content-Type': 'multipart/form-data'},
            body: formData,
          }
        );
        const imgs_response = await response.json();
        console.log(imgs_response);
        if(imgs_response.length> 0){
      try {
        const res = await fetch(
          `https://test-wrangler.listing.workers.dev/api/add-listing`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              sub_category_id: selectedCategory,
              rating: listing.rating,
              rating_count: listing.rating_count,
              menu_type: { menus },
              location: location,
              services: { "services": selected},
              description: listing.description,
              url: listing.url,
              image_path: listing.image_link,
              video_path: listing.video_link,
              user_id: selectedUser,
              name: listing.name,
              address: listing.address,
              max_price: listing.max_price,
              min_price: listing.min_price,
              timing: listing.start_time + " to " + listing.end_time,
              phone_number: listing.phn_number,
              start_time: listing.start_time,
              end_time: listing.end_time,
              longitude: selectedLocation.lng,
              latitude: selectedLocation.lat,
            }),
          }
        );
        const result = await res.json();
        if (result.status === true) {
          setMessageAlert("Business List Added Succcessfully. ");
          setStatusAlert("success");
          setShowAlert(true);
          setListing({
            user_id: "",
            location: "",
            category_id: "",
            rating: "",
            rating_count: "",
            image_link: "",
            video_link: "",
            url: "",
            start_time: "",
            end_time: "",
            max_price: "",
            min_price: "",
            phn_number: "",
            description: "",
            name: "",
            address: "",
          });
          setLocation("");
          setSelectedLocation("");
          setServices([]);
          setMenu([]);
        }
      } catch (e) {
        console.log(e);
      }
    }
    }  catch (e) {
      console.log(e);
    }
  }
  };
  function handleImageInput(e) {
    imageData.push(e.target.files);
    let ImagesArray = Object.entries(e.target.files).map((e) =>
      URL.createObjectURL(e[1])
    );
    console.log(ImagesArray);
    setSingleImage([...singleImage, ...ImagesArray]);
  }
  const handleInput = (e) => {
    e.preventDefault();
    setListing({ ...listing, [e.target.name]: e.target.value });
  };

  const handleChange = (e) => {
    setSelectedUser(e.target.value);
  };

  const getServicesById = async (id) => {
    try {
      const res = await fetch(
        `https://test-wrangler.listing.workers.dev/api/get-service-by-id/${id}`,
        {
          method: "GET",
        }
      );
      const result = await res.json();
      console.log(result);
      setgetservices(result);
      //console.log("user data", result.results[0]);
      // console.log("user data", userData);
    } catch (e) {
      console.log("error", e);
    }
  };
  const hiddenFileInput = React.useRef(null);

  const handleBtnClick = (e) => {
    hiddenFileInput.current.click();
  };
  // useEffect(() => {
  //   console.log("here useEffect", selectedUser)
  // },[selectedUser])
  return (
    <div className="dash-body">
      <div className="dash-user-content flex-direction-row">
        <div className="d-flex align-items-center total-over-add">
          <Link
            to="/admin/listing"
            className="anch-color d-flex justify-content-end"
            style={{
              background: "transparent",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={longLeftArrow} alt="..." />
            <p
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "15px",
              }}
            >
              Go to Listing Page
            </p>
          </Link>
        </div>
      </div>
      <div className="add-listing-form-wrapper">
        <div className="businessFormWrapper">
          <img src={AddBusiness} alt="..." width="29" height="29" />
          <p className="formHeading">List Your Business</p>
        </div>
        <form className="login-form ">
          <div className="row">
            <div className="col-md-4">
              <div className="mb-3">
                <Label className="edit-input-label">User Name</Label>
                <select
                  className="edit-form-control padding-rigth-15"
                  onChange={handleChange}
                  // onChange={(e) => {
                  //   // console.log("here 1", e.target.value)
                  //   setSelectedUser(e.target.value);
                  // }}
                >
                  {users?.map((element) => {
                    return (
                      <option value={element.id} key={element.id}>
                        {element.first_name + " " + element.last_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <Label className="edit-input-label">Phone Number</Label>
                <input
                  type="tel"
                  name="phn_number"
                  onChange={handleInput}
                  className="edit-form-control"
                  placeholder="Phone Number"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <Label className="edit-input-label">Listing Name</Label>
                <input
                  type="text"
                  name="name"
                  onChange={handleInput}
                  className="edit-form-control"
                  placeholder="Listing Name"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="mb-3">
                <Label className="edit-input-label">Category Name</Label>
                <select
                  className="edit-form-control padding-rigth-15"
                  onChange={(e) => {
                    setSelectedCategory(e.target.value);
                    getServicesById(e.target.value);
                  }}
                >
                  {categories?.map((element) => {
                    return (
                      <option value={element.id} selected>
                        {element.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <Label className="edit-input-label">Menu</Label>
                <TagsInput
                  value={menus}
                  onChange={setMenu}
                  name="Menu"
                  placeHolder="Add Menu"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <Label className="edit-input-label">Services</Label>
                <Select
                  isMulti
                  // components={animatedComponents}
                  closeMenuOnSelect={false}
                  options={getservices}
                  onChange={handleselectChange}
                  autoFocus={true}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="mb-3">
                <Label className="edit-input-label">Rating</Label>
                <input
                  type="text"
                  name="rating"
                  onChange={handleInput}
                  className="edit-form-control"
                  placeholder="Rating"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <Label className="edit-input-label">Rating Count</Label>
                <input
                  type="text"
                  name="rating_count"
                  onChange={handleInput}
                  className="edit-form-control"
                  placeholder="Rating count"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <Label className="edit-input-label">Image</Label>
                <input
                  type="text"
                  name="image_link"
                  onChange={handleInput}
                  className="edit-form-control"
                  placeholder="Image Link"
                />
              </div>
            </div>
          </div>
          {/* new fields */}
          <div className="row">
            <div className="col-md-4">
              <div className="mb-3">
                <Label className="edit-input-label">Start Time</Label>
                <input
                  type="time"
                  name="start_time"
                  onChange={handleInput}
                  className="edit-form-control"
                  placeholder="Start Time"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <Label className="edit-input-label">End Time</Label>
                <input
                  type="time"
                  name="end_time"
                  onChange={handleInput}
                  className="edit-form-control"
                  placeholder="End Time"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <Label className="edit-input-label">Minimum Price</Label>
                <input
                  type="number"
                  name="min_price"
                  onChange={handleInput}
                  className="edit-form-control"
                  placeholder="Minimum Price"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="mb-3">
                <Label className="edit-input-label">Maximum Price</Label>
                <input
                  type="number"
                  name="max_price"
                  onChange={handleInput}
                  className="edit-form-control"
                  placeholder="Maximum Price"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <Label className="edit-input-label">Video</Label>
                <input
                  type="text"
                  name="video_link"
                  onChange={handleInput}
                  className="edit-form-control"
                  placeholder="Video Link"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <Label className="edit-input-label">URL</Label>
                <input
                  type="text"
                  name="url"
                  onChange={handleInput}
                  className="edit-form-control"
                  placeholder="URL"
                />
              </div>
            </div>
            {/* <div className="col-md-4">
                  <div className="mb-3">
                    <Label className="edit-input-label">Timing</Label>
                    <input
                      type="text"
                      name="timing"
                      onChange={handleInput}
                      className="edit-form-control"
                      placeholder="Timing"
                    />
                  </div>
                </div> */}

            <div className="col-md-12">
              {/* <div className="mb-3">
                    <Label className="edit-input-label">Address</Label>
                    <input
                      type="text"
                      name="address"
                      onChange={handleInput}
                      className="edit-form-control"
                      placeholder="Address"
                    />
                  </div> */}
              <Places />
            </div>
          </div>
          <div className="mb-3 mt-3">
            <Label className="edit-input-label">Description</Label>
            <textarea
              type="text"
              name="description"
              onChange={handleInput}
              className="about-input"
              placeholder="Description"
              rows={8}
              cols={40}
            />
          </div>
          <div className=" mt-3">
        <Label className="edit-input-label">Upload Images Up to 5</Label>
        </div>
          <div onClick={handleBtnClick} className="uploadimage-box">
            <img src={cloud} alt="" width="100px" height="91px" />
          </div>

          <div className="imagePreview-wrapper">
            {singleImage.length > 0 &&
              singleImage.map((item) => {
                return (
                  <div>
                    <img src={item} alt="" className="imagePreview" />
                  </div>
                );
              })}
          </div>
          <input
            ref={hiddenFileInput}
            style={{ display: "none" }}
            multiple
            disabled={singleImage.length === 5}
            accept="image/*"
            type={"file"}
            name="file"
            onChange={handleImageInput}
          />
          {showAlert ? (
            <Alert message={showMessage} type={showStatus} />
          ) : (
            <div></div>
          )}

        
        <div className="login-form">
          
          <div className="addlistingbtnwrapper">
          </div>
        </div>
          <div className="row">
            <div className="col-md-6">
              <button
                className="bg-white-cusd"
                onClick={(e) => {
                  e.preventDefault();
                  setModalShow(false);
                }}
              >
                Close
              </button>
            </div>
            <div className="col-md-6">
              <button onClick={addListing}>Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default DashboardListingForm;
