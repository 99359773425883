import React from 'react'
import DashboardBody from '../components/DashboardBody'
import DashboardSideNavigationBar from '../components/DashboardSideNavigationBar'

export default function Dashboard() {
  return (
    <div className="dashboard-wrapper d-flex">
      <DashboardSideNavigationBar/>  
      <DashboardBody/>
    </div>
  )
}
  