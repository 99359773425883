import React from 'react'
import CategoriesBody from '../components/CategoriesBody'
import DashboardSideNavigationBar from '../components/DashboardSideNavigationBar'
function categories() {
  return (
    <div className="dashboard-wrapper d-flex">
      <DashboardSideNavigationBar/>  
      <CategoriesBody />
    </div>
  )
}

export default categories