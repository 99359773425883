import React, {useState, createContext, useEffect} from 'react'
import CategoriesTable from './CategoriesTable'
import DashboardHeader from './DashboardHeader'
import { Link } from 'react-router-dom'
import add from '../imgs/icons/add.png'
import { Label } from "reactstrap";
import Modal from "react-bootstrap/Modal";
import pen from "../imgs/icons/pen.png";
import { Alert } from 'antd';
export const CategoryGlobals = createContext();
function CategoriesBody() {
    const [modalShow, setModalShow] = React.useState(false);
    const [showAlert, setShowAlert]= useState(false);
    const [showStatus, setStatusAlert]= useState('');
    const [showMessage, setMessageAlert]= useState('');
    const [categoriesList, setCategoriesList] = React.useState([]);
    const [categoryInput, setcategoryInput] = React.useState('');
    const [categoryType, setCategoryType] = useState(0)
    const [categories,setCategories] = useState([]);
    const [initialpage, setInitialpage] = useState(0)
    const [showTableLoader , setTableloader] = useState(false)
    const [filterState, setFilterState] = useState(false)



    useEffect(() => {
      data();
      setInitialpage(0)
    }, []);
    const data = async () => {
      setTableloader(true)
      try {
        const res = await fetch(`https://test-wrangler.listing.workers.dev/api/get-all-categories?page=1&limit=10`, {
          method: "GET",
        });
        const result = await res.json();
        console.log(result, "categories result");
        setTableloader(false)
        setCategories(result);
      } catch (err) {
        console.log(err.message);
      }
    };
    const data1 = async (page, limit) => {
      setTableloader(true)
      try {
        const res = await fetch(`https://test-wrangler.listing.workers.dev/api/get-all-categories?page=${page}&limit=${limit}`, {
          method: "GET",
        });
        const result = await res.json();
        setTableloader(false)
        setCategories(result);
      } catch (err) {
        console.log(err.message);
      }
    };
    const addCategory = async () => {
      setShowAlert(false)
      const response = await fetch('https://test-wrangler.listing.workers.dev/api/get-parent-categories',
      {
                method:"GET",
            });
      const data = await response.json();
      setCategoriesList(data.results);
      setModalShow(true);
    };

  const createCategory = async(e)=>{
    e.preventDefault();
            if(categoryInput !==""){
            const response = await fetch('https://test-wrangler.listing.workers.dev/api/add-category',{
                method:"POST",
                body: JSON.stringify({
                        "name": categoryInput,
                        "category_id": categoryType,
                        "image": null,
                })
            });
            data();
            setMessageAlert("Category Added Successfully")
            setStatusAlert("succcess")
            setShowAlert(true)
            setModalShow(false)
          }else{
            setMessageAlert("Please Enter Category Name")
            setStatusAlert("error")
            setShowAlert(true)
          }

            // const data = await response.json();
            // if(data.status===0)
            // {
            //   setMessageAlert(data.msg)
            //   setStatusAlert("error")
            //   setShowAlert(true)
            // }
            // else if(data.status===1)
            // {
            //   
            // }
            // else {
            //   setMessageAlert(data.msg)
            //   setStatusAlert("success")
            //   setShowAlert(true)
            //   setModalShow(false)
            // }
}
  
  return (
    <CategoryGlobals.Provider value={{data: data, data1: data1 , setCategories:setCategories ,setTableloader:setTableloader ,setInitialpage:setInitialpage,categories: categories, showTableLoader:showTableLoader, initialpage:initialpage ,setFilterState:setFilterState, 
      filterState:filterState}}>
    <div class="dash-body">
        <DashboardHeader title={"Categories"} />

    <div className="dash-user-content">
    <div class="d-flex align-items-center total-over-add">
        <Link onClick={addCategory} class="ms-auto add-user-btn"><img src={add} alt="..."/>Add Category</Link>
    </div>
    </div>

    <div class="over-tbl-content">
        <CategoriesTable/>
    </div>

    <Modal
        className="edit-modal"
        show={modalShow}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="d-flex justify-content-center color-orange font-family-poppins">
          <img style={{ cursor: "pointer" }} src={pen} alt="..." width="24" />
          <Modal.Title id="contained-modal-title-vcenter">
            &nbsp; Create Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="login-form " onSubmit={createCategory}>
            <div className="mb-3">
            <Label className="edit-input-label">Category name</Label>
              <input
                type="text"
                name="Category Name"
                value={categoryInput.name}
                onChange={e=>setcategoryInput(e.target.value)}
                className="edit-form-control"
                placeholder="Category Name"
              />
            </div>
            <div className="mb-3">
            <Label className="edit-input-label">Parent Category</Label>
              <select className="edit-form-control padding-rigth-15"
              value = {categoryType.id} 
              onChange={e=>setCategoryType(e.target.value)}
               >
                <option value={0}>None</option>
                {categoriesList?.map((element) => {
                  return (      
                      <option value={element.id}>{element.name}</option>
                  );})}
                
              </select>
        </div>
        {showAlert? <Alert message={showMessage} type={showStatus} />: <div></div>}
            <div className="row">
              <div className="col-md-6">
                <button
                  variant="secondary"
                  className="bg-white-cusd"
                  onClick={() => setModalShow(false)}
                >
                  Close
                </button>
              </div>
              <div className="col-md-6">
                <button variant="primary">Save</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
    </CategoryGlobals.Provider>
  )
}

export default CategoriesBody