import React, { useContext } from 'react'
import person from '../../imgs/icons/person.png'
import { Label } from 'reactstrap'
import { Alert } from 'antd';
import  {CreateListingGlobals } from './ListingForm'

function PersonalInfo() {
  const {setPageNumber,userData,setUserData, showStatus, setStatusAlert, showMessage, setMessageAlert, showAlert, setShowAlert, updatedAbout, setUpdatedAbout} = useContext(CreateListingGlobals);

  const handleInput = (e) => {
    e.preventDefault();
    setUpdatedAbout({...updatedAbout, [e.target.name]: e.target.value })
  };
 
      const handleClick=(e)=>{
        e.preventDefault();
        console.log("abut:  ", updatedAbout);
    if (userData.first_name === "") {
      setMessageAlert("Please enter first name");
      setStatusAlert("error");
      setShowAlert(true);
    } else if (userData.last_name === "") {
      setMessageAlert("Please enter last name");
      setStatusAlert("error");
      setShowAlert(true);
    }else{
      setPageNumber(2);
    }
      }
  return (
    <div>

        <div className='add-listing-form-wrapper'>
            <div className='businessFormWrapper'>
            <img src={person} alt="..." width="29" height="29"/>
             <p className='formHeading'>Add Your Info</p>
             
        </div>
        <div className="login-form">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <Label className="edit-input-label">First Name</Label>
                  <input
                    type="text"
                    name="first_name"
                    onChange={handleInput}
                   value={userData.first_name}
                    className="edit-form-control"
                    placeholder="First Name"
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                <Label className="edit-input-label">Last Name</Label>
                  <input
                    type="text"
                    name="last_name"
                   onChange={handleInput}
                   value={userData.last_name}
                    className="edit-form-control"
                    placeholder="Last Name"
                    disabled
                  />
                </div>
              </div>
            </div>   
            <div className="row">
              <div className="mb-3">
              {/* <div className="mb-3">
                  <Label className="edit-input-label">Business Phone No</Label>
                  <input
                    type="tel"
                    name="phone_Number"
                    onChange={handleInput}
                   // value={editUser.email}
                    className="edit-form-control"
                    placeholder="+92 345 5553532"
                  />
                </div> */}
              </div>
              {/* <div className="col-md-6"> */}
                <div className="mb-3">
                  <Label className="edit-input-label">Email</Label>
                  <input
                    type="email"
                    name="email"
                    onChange={handleInput}
                    value={userData.email}
                    className="edit-form-control"
                    placeholder="Email"
                    disabled
                  />
                </div>
                {/* </div> */}
            </div>    
            <div className="mb-3">
              <Label className="edit-input-label">About</Label>
              <textarea
                type="about"
                name="about"
                value={userData.about}
                onChange={handleInput}
                className="about-input"
                placeholder="About"
                rows={4}
                cols={40}
              />
            </div>
            {showAlert ? (
              <Alert message={showMessage} type={showStatus} />
            ) : (
              <div></div>
            )}
            <div className='addlistingbtnwrapper'>
            <div className="addlistingformBtn">
              <div className="col-md-6">
                <button 
                className='btn btn-primary'
                onClick={handleClick}
                >
                  NEXT
                  </button>
              </div>
            </div>
            </div>
          </div>
          </div>
    </div>
  )
}

export default PersonalInfo