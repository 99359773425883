import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import search from "../imgs/icons/search.png";
function SearchBar(props) {

  const navigate = useNavigate()
  const [keyword,setKeyword] = useState("");
  const { setCheckBox, setFilterState, setPriceStyle, setRatingStyle,  setFilters , filterState} = props
  const [input,setInput] = useState(props.query);
  const getListing = (e) => {
    if(!filterState){

      e.preventDefault();
      navigate(`/listing/0/?page=1&limit=2&query=${keyword}`)
    }else{
      setCheckBox(false)
      setFilterState(false);
      setFilters({
        price: null,
        rating: null,
        service: []
      })
      setPriceStyle("")
      setRatingStyle("")
      e.preventDefault();
      navigate(`/listing/0/?page=1&limit=2&query=${keyword}`)
    }

  }
  return (
    <>
    <form class="d-flex search-banner justify-content-center" role="search" onSubmit={getListing} >
    <input class="form-control me-2" type="search" placeholder="Search" value={input} onChange={(e) => { setKeyword(e.target.value); setInput(e.target.value); }} style={{color:'black'}} aria-label="Search"/>
    
    <button type="submit"><img src={search} alt="..."/></button>
    </form>
    </>
  );
}
export default SearchBar;
