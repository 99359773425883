import React, { useEffect, useState, } from "react";
import { Link } from "react-router-dom";

import { Label } from "reactstrap";
import { Alert } from "antd";

import AddBusiness from "../imgs/AddBusiness.png";
import longLeftArrow from "../imgs/icons/longLeftArrow.png";


function PaymentMethodBody() {
  const [showAlert, setShowAlert] = useState(false);
  const [showStatus, setStatusAlert] = useState("");
  const [showMessage, setMessageAlert] = useState("");
  const [flag1, setFlag1] = useState(false);
  const [flag2, setFlag2] = useState(false);
  const [flag3, setFlag3] = useState(false);
  const [plan, setPlan] = useState(0);
  const [userPaymentData, setUserPaymentData] = useState({
    expiration_date: '',
    security_code: '',
    card_number: '',
  });
  const token = localStorage.getItem('token')
  const username = localStorage.getItem('username')
  const userrole = localStorage.getItem('Status')
  const userid = localStorage.getItem("id");
  
  const handleInput = (e) => {
    e.preventDefault();
    setUserPaymentData({ ...userPaymentData, [e.target.name]: e.target.value });
  };
  
  const SubscribePackage = async (e) =>{
    e.preventDefault();
    if(userPaymentData.card_number===""){
      setMessageAlert("Please Enter Card Number");
      setStatusAlert("error");
      setShowAlert(true);
    }else if(userPaymentData.expiration_date===""){
      setMessageAlert("Please Enter Card Expiry Date");
      setStatusAlert("error");
      setShowAlert(true);
    }else if(userPaymentData.security_code=== ""){
      setMessageAlert("Please Enter CVC Code Provided at the back of Card");
      setStatusAlert("error");
      setShowAlert(true);
    }else if(plan === 0){
      setMessageAlert("Please Select Package Plan");
      setStatusAlert("error");
      setShowAlert(true);
    }else{
      const response = await fetch(`https://test-wrangler.listing.workers.dev/api/subscribe-user/${localStorage.getItem('id')}`,{
                    method:"POST",
                    headers: {'Content-Type':'application/json'},
                    body: JSON.stringify({
                            "user_registration": userPaymentData.card_number,
                            "security_code": userPaymentData.security_code,
                            "expiration_date": userPaymentData.expiration_date,
                            "plan_id": plan,
                    })
                });
                const data = await response.json();
                console.log(data);

                if(data.success===true){
                  setMessageAlert("You are subscribed Successfully");
                  setStatusAlert("success");
                  setShowAlert(true);
                  localStorage.setItem("id", '3');
                  localStorage.setItem("Status", '3');


                }
    }
    
  }
  const Package1 = () => {setFlag1(true); setPlan(1); setFlag2(false); setFlag3(false)};
  const Package2 = () => {setFlag2(true); setPlan(2); setFlag1(false); setFlag3(false)};
  const Package3 = () => {setFlag3(true); setPlan(3); setFlag2(false); setFlag1(false)};

  return (
    <div className="payment-content-wrapper">
      <div className="dash-user-content flex-direction-row">
        <div className="d-flex align-items-center total-over-add">
          <Link
            to="/"
            className="anch-color d-flex justify-content-end"
            style={{
              background: "transparent",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={longLeftArrow} alt="..." />
            <p
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "15px",
              }}
            >
              Go to Home Page
            </p>
          </Link>
        </div>
      </div>
      <div className="add-listing-form-wrapper">
        <div className="businessFormWrapper">
          <img src={AddBusiness} alt="..." width="29" height="29" />
          <p className="formHeading">Register Your Business</p>
        </div>
        <div className="row">
          <div className="overview-content col-md-6">
            <div className="col-lg-10">
              <div className={ flag1===true? 'payment-card-active mb-3': 'payment-card mb-3'} onClick={Package1}>
                <h3 className="d-flex align-items-center">Bronze</h3>

                <div className={flag1===true? "payment-card-content-active mt-4" : "payment-card-content mt-4"}>
                  <p>$2</p>
                  <p>5 Listings</p>
                </div>
              </div>
            </div>
            <div className="col-lg-10">
              <div className={ flag2===true? 'payment-card-active mb-3': 'payment-card mb-3'} onClick={Package2}>
                <h3 className="d-flex align-items-center">Silver</h3>
                <div className={flag2===true? "payment-card-content-active mt-4" : "payment-card-content mt-4"}>
                  <p>$10</p>
                  <p>30 Listings</p>
                </div>
              </div>
            </div>
            <div className="col-lg-10">
              <div className={ flag3===true? 'payment-card-active mb-3': 'payment-card mb-3'} onClick={Package3}>
                <h3 className="d-flex align-items-center">Gold</h3>
                <div className={flag3===true? "payment-card-content-active mt-4" : "payment-card-content mt-4"}>
                  <p>$20</p>
                  <p>60 Listings</p>
                </div>
              </div>
            </div>
          </div>

          <form className="login-form col-md-6">
          <h1 className="paytment-info-hdng">Payment Info</h1>
            <div className="col">
              <div className="col-md-10">
                <div className="mb-3">
                  <Label className="edit-input-label">Card Number</Label>
                  <input
                    className="edit-form-control"
                    onChange={handleInput}
                    type="text"
                    name="card_number"
                    placeholder="Card Number"
                  />
                </div>
              </div>
              <div className="col-md-10">
                <div className="mb-3">
                  <Label className="edit-input-label">Security Code (CVC)</Label>
                  <input
                    type="password"
                    name="security_code"
                    onChange={handleInput}
                    className="edit-form-control"
                    placeholder="Security Code (CVC)"
                  />
                </div>
              </div>
              <div className="col-md-10">
                <div className="mb-3">
                  <Label className="edit-input-label">Expiration Date</Label>
                  <input
                    type="date"
                    name="expiration_date"
                    onChange={handleInput}
                    className="edit-form-control"
                    placeholder="Expiration Date"
                  />
                </div>
              </div>
              {showAlert ? (
                <div className="col-md-10 mb-3">
                                <Alert message={showMessage} type={showStatus} />

                </div>
            ) : (
              ""
            )}
              <div className="payment-btn-container col-md-10 mb-3 mt-5">
                <button
                onClick={SubscribePackage}
                >
                  Save
                </button>
              </div>
            </div>

            
          </form>
        </div>
      </div>
    </div>
  );
}

export default PaymentMethodBody;
