import React, {useContext } from "react";
import { CircularProgress } from "@mui/material";
import { Globals } from "./DashboardBody";

export default function DashboardOverViewContent({ users }) {
  const { Cardsloader, totalUsers, totallisting } = useContext(Globals);
  

  return (
    <div class="overview-content">
      <div class="row gx-4">
        <div class="col-lg-4">
          <div class="overview-box">
            {Cardsloader === false ? (
              <div>
                <h6 class="d-flex align-items-center">Total Users</h6>
                <p>{totalUsers}</p>
              </div>
            ) : (
              <div className="list-box d-flex justify-content-center align-items-center">
                <CircularProgress style={{ color: "#FF5348" }} />
              </div>
            )}
          </div>
        </div>
        <div class="col-lg-4">
          <div class="overview-box">
            {Cardsloader === false ? (
              <div>
                <h6 class="d-flex align-items-center">
                  Listings
                </h6>
                <p>
                  {totallisting}
                </p>
              </div>
            ) : (
              <div className="list-box d-flex justify-content-center align-items-center">
                <CircularProgress style={{ color: "#FF5348" }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
