import React, { useState, useContext , useRef} from "react";
import pen from "../imgs/icons/pen.png";
import Graypen from "../imgs/icons/graypen.png"
import Graytrash from "../imgs/icons/graytrash.png"
import trash from "../imgs/icons/trash.png";
import Modal from "react-bootstrap/Modal";
import { Label } from "reactstrap";
import { Alert } from "antd";
import Search from "./GuiFilter";
import { Globals } from "./DashboardBody";
import Button from "@mui/material/Button";
import Pagination from "./Paginaton";
import { CircularProgress } from "@mui/material";
import UserSorting from "./UserSorting";
export default function UserTable() {
  const [modalShow, setModalShow] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showDeleteStatus, setDeleteStatusAlert] = useState(false);
  const [showDeleteMessage, setDeleteMessageAlert] = useState("");
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [editUser, setEditUser] = React.useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [showStatus, setStatusAlert] = useState("");
  const [showMessage, setMessageAlert] = useState("");
  const [userRole, setUserRole] = useState("");
  const [sorting, setsorting] = useState(false)
  const childCompRef = useRef();
  const {
    allUsers,
    data,
    CardsData,
    data1,
    initialpage,
    setInitialpage,
    showTableLoader,
    setTableloader,
    setAllUsers,
    setFilterState, 
    filterState
  } = useContext(Globals);
  const [deleteUserId, setDeleteUserId] = useState("");
  const [items, setItems] = useState([]);
  const [isChecked, setisChecked] = useState([]);

  const getEditData = async (user) => {
    try {
      const res = await fetch(
        `https://test-wrangler.listing.workers.dev/api/edit-user/${user.id}`,
        {
          method: "POST",
        }
      );
      data();
      const result = await res.json();
      setEditUser(result.results[0]);
      setUserRole(result.results[0].user_role);
    } catch (e) {
      console.log("error", e);
    }
    setModalShow(true);
  };

  const handleInput = (e) => {
    e.preventDefault();
    let copyEditUser = editUser;
    copyEditUser = { ...copyEditUser, [e.target.name]: e.target.value };
    setEditUser(copyEditUser);
  };

  const ShowDeleteModal = (id) => {
    setShowAlert(false);
    setDeleteModalShow(true);
    setDeleteUserId(id);
  };
  const deleteUser = async () => {
    try {
      const res = await fetch(
        `https://test-wrangler.listing.workers.dev/api/delete-user/${deleteUserId}`,
        {
          method: "DELETE",
        }
      );
      const result = await res.json();
      console.log(result);
      if (result.status === true) {
        CardsData();
        setMessageAlert(result.msg);
        setStatusAlert("success");
        setShowAlert(true);
        data();
      } else {
        setMessageAlert(result.msg);
        setStatusAlert("error");
        setShowAlert(true);
      }
    } catch (err) {
      setMessageAlert(err.msg);
      setStatusAlert("error");
      setShowAlert(true);
      setMessageAlert(err.msg);
      setStatusAlert("error");
      setShowAlert(true);
    }
  };
  const UpdateUser = async (e) => {
    e.preventDefault();
    if (editUser.first_name === "") {
      setMessageAlert("Please enter first name");
      setStatusAlert("error");
      setShowAlert(true);
    } else if (editUser.last_name === "") {
      setMessageAlert("Please enter last name");
      setStatusAlert("error");
      setShowAlert(true);
    } else {
      try {
        const res = await fetch(
          `https://test-wrangler.listing.workers.dev/api/save-user/${editUser.id}`,
          {
            method: "POST",
            body: JSON.stringify({
              first_name: editUser.first_name,
              last_name: editUser.last_name,
              about: editUser.about,
              password: editUser.password ? editUser.password : "",
              password: editUser.password ? editUser.password : "",
              user_role: userRole,
            }),
          }
        );
        data();
        const result = await res.json();
        console.log(result);
        setMessageAlert("User updated successfully");
        setStatusAlert("success");
        setShowAlert(true);
        setModalShow(false);
        setShowAlert(false);
        setUserRole("");
      } catch (e) {
        console.log("error", e);
      }
    }
  };
  const count = allUsers.count;
  const limit = 10;
  const filterdData = allUsers.results?.filter((val) =>
    val?.first_name?.toLowerCase()?.includes(query?.toLowerCase())
  );
  const tableData = filterdData ?? allUsers;

  const handlePageClick = (data) => {
    if(!filterState){
      let currentPage = data.selected + 1;
      const commentsFormServer = data1(currentPage, limit);
  
      setInitialpage(data.selected);
      // console.log(initialpage)
      setItems(commentsFormServer);
      setisChecked([])

    }else{
      let currentPage = data.selected + 1;
      setInitialpage(data.selected)
      const commentsFormServer = childCompRef.current.searchByPage( `https://test-wrangler.listing.workers.dev/api/search-user?page=${currentPage}&limit=10`);
      setItems(commentsFormServer);
      setisChecked([]) 
    }
  };

  const handelCheckbox = (e) => {
    const { value, checked } = e.target;
    console.log(value,'value', checked,"checked")
    if (checked) {
      setisChecked([...isChecked, value], value);
    } else {
      setisChecked(isChecked.filter((e) => e !== value));
    }
  };
  const allDelete = async () => {
    const response = await fetch(
      `https://test-wrangler.listing.workers.dev/api/bulk-delete-user`,
      {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          deleteData: isChecked,
        }),
      }
    );
    const result = await response.json();
    setShowDeleteAlert(true);
    setDeleteMessageAlert(result.msg);
    setDeleteStatusAlert(result.status);
    data();
    CardsData();
    setisChecked([]);
  };
  setTimeout(() => {
    setShowDeleteAlert(false);
  }, 7000);

  const orderBy = async (column, order) => {
    try {
      const response = await fetch(
        "https://test-wrangler.listing.workers.dev/api/order-by-user?page=1&limit=10",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            column: column,
            order: order,
          }),
        }
      );
      const data = await response.json();
      setAllUsers(data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="d-flex del-search">
        {
          <Button
            disabled={isChecked.length > 0 ? false : true}
            variant="contained"
            color="error"
            onClick={allDelete}
          >
            Delete
          </Button>
        }
        {showDeleteAlert ? (
          <Alert message={showDeleteMessage} type="error" />
        ) : (
          ""
        )}
        <Search
          placeholder={"Search User by name"}
          url={`https://test-wrangler.listing.workers.dev/api/search-user?page=1&limit=10`}
          state={setAllUsers}
          setLoader={setTableloader}
          setInitialpage={setInitialpage}
          data={data}
          setFilterState={setFilterState}
          ref={childCompRef}

        />
      </div>
      <div className="user-table-wrapper">
        <table className="user-tbl" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>#</th>
              <th>
                <UserSorting name="Name" col="first_name"
                url="https://test-wrangler.listing.workers.dev/api/order-by-user?page=1&limit=10"
                setFunction={setAllUsers}
                setsorting={setsorting} 
                sorting={sorting} />
              </th>
              <th>
                <UserSorting name="Role" col="user_role"
                url="https://test-wrangler.listing.workers.dev/api/order-by-user?page=1&limit=10"
                setFunction={setAllUsers}
                setsorting={setsorting} 
                sorting={sorting} />
              </th>
              <th>
                <UserSorting name="Email" col="email"
                url="https://test-wrangler.listing.workers.dev/api/order-by-user?page=1&limit=10"
                setFunction={setAllUsers}
                setsorting={setsorting} 
                sorting={sorting} />
              </th>
              <th>
                <UserSorting name="About" col="about"
                url="https://test-wrangler.listing.workers.dev/api/order-by-user?page=1&limit=10"
                setFunction={setAllUsers}
                setsorting={setsorting} 
                sorting={sorting} />
              </th>
              <th>Quick Action</th>
            </tr>
          </thead>
          {showTableLoader === false ? (
            <tbody>
              {tableData?.map((user) => {
                return (
                  <tr key={user.id}>
                    <td>
                      <input
                        type="checkbox"
                        value={user.id}
                        checked={user.isChecked}
                        onChange={(e) => handelCheckbox(e)}
                      />
                    </td>
                    <td>
                      <div className="d-flex company-cell">
                        <input
                          value={user.id}
                          type={"hidden"}
                          className="hidden-user-id"
                        />
                        <span>
                          {user.first_name} {user.last_name}
                        </span>
                      </div>
                    </td>
                    <td>
                      <span className=" customer-cell ">{user.name}</span>
                    </td>
                    <td>{user.email}</td>
                    <td>
                      <span maxlength="30">{user.about}</span>
                    </td>
                    <td>
                      { isChecked.length > 0 ?
                      <span className="quick-act-ico d-flex">
                        <img
                          style={{ cursor: "pointer" }}
                          src={Graypen}
                          alt="..."
                          width="24"
                        />
                        <img
                          style={{ cursor: "pointer" }}
                          src={Graytrash}
                          alt="..."
                          width="24"
                          className="ms-2"
                        />
                      </span>: (<span className="quick-act-ico d-flex">
                        <img
                          onClick={(e) => getEditData(user)}
                          style={{ cursor: "pointer" }}
                          src={pen}
                          alt="..."
                          width="24"
                        />
                        <img
                          onClick={() => ShowDeleteModal(user.id)}
                          style={{ cursor: "pointer" }}
                          src={trash}
                          alt="..."
                          width="24"
                          className="ms-2"
                        />
                      </span>)  }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <td colspan="6" style={{ padding: "10px" }}>
              <center>
                <CircularProgress
                  style={{ color: "#FF5348", textAlign: "center" }}
                />
              </center>
            </td>
          )}
        </table>

        {/* Update User Modal */}
        
        <Modal
          className="edit-modal"
          show={modalShow}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="d-flex justify-content-center color-orange font-family-poppins">
            <img style={{ cursor: "pointer" }} src={pen} alt="..." width="24" />
            <Modal.Title id="contained-modal-title-vcenter">
              &nbsp; Edit User
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="login-form ">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <Label className="edit-input-label">First Name</Label>
                    <input
                      type="text"
                      name="first_name"
                      onChange={handleInput}
                      value={editUser.first_name}
                      className="edit-form-control"
                      placeholder="First Name"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <Label className="edit-input-label">Last Name</Label>
                    <input
                      type="text"
                      name="last_name"
                      onChange={handleInput}
                      value={editUser.last_name}
                      className="edit-form-control"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <Label className="edit-input-label">
                      Email*{" "}
                      <span className="edit-from-control-msg">
                        (Email is not editable)
                      </span>
                    </Label>
                    <input
                      type="email"
                      name="email"
                      onChange={handleInput}
                      value={editUser.email}
                      className="edit-form-control"
                      placeholder="Eamil"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <Label className="edit-input-label">Password</Label>
                    <input
                      type="password"
                      name="password"
                      onChange={handleInput}
                      value={editUser.password}
                      className="edit-form-control"
                      placeholder="Password"
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <Label className="edit-input-label">Role</Label>
                <select
                  className="edit-form-control padding-rigth-15"
                  value={userRole}
                  onChange={(e) => setUserRole(e.target.value)}
                >
                  <option>Select</option>
                  <option value={1}>Admin</option>
                  <option value={2}>User</option>
                  <option value={3}>Member</option>
                </select>
              </div>
              <div className="mb-3">
                <Label className="edit-input-label">About</Label>
                <textarea
                  type="about"
                  name="about"
                  value={editUser.about}
                  onChange={handleInput}
                  className="about-input"
                  placeholder="About"
                  rows={8}
                  cols={40}
                />
              </div>
              {showAlert ? (
                <Alert message={showMessage} type={showStatus} />
              ) : (
                <div></div>
              )}
              <div className="row">
                <div className="col-md-6">
                  <button
                    className="bg-white-cusd"
                    onClick={(e) => {
                      e.preventDefault();
                      setModalShow(false);
                    }}
                  >
                    Close
                  </button>
                </div>
                <div className="col-md-6">
                  <button onClick={UpdateUser}>Save</button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        {/* Delete User Modal */}
        <Modal
          className="edit-modal"
          show={deleteModalShow}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="d-flex justify-content-center color-orange font-family-poppins">
            <img
              style={{ cursor: "pointer" }}
              src={trash}
              alt="..."
              width="24"
            />
            <Modal.Title id="contained-modal-title-vcenter">
              &nbsp; Delete User
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <h4 className="delete-user-text">
                Are you sure you want to delete this user?
              </h4>
            </div>
            <form className="login-form " onSubmit={deleteUser}>
              <div className="row">
                <div className="col-md-6">
                  <button
                    className="bg-white-cusd"
                    onClick={(e) => { e.preventDefault();
                      setDeleteModalShow(false)}}
                  >
                    Close
                  </button>
                </div>
                <div className="col-md-6">
                  <button type="submit" className="add-user-btn">
                    Delete
                  </button>
                </div>
              </div>
            </form>

            {showAlert ? <Alert message={showMessage} type={showStatus} /> : ""}
          </Modal.Body>
        </Modal>
      </div>
      <Pagination
        count={count}
        limit={limit}
        handlePageClick={handlePageClick}
        initialpage={initialpage}
      />
    </>
  );
}
