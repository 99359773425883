import React from 'react'
import Footer from '../components/inc/Footer'
import Header from '../components/inc/Header'
import ListingDetail from '../components/ListingDetail'
import { useParams } from "react-router-dom";

function ListingViewDetail() {
const {id} = useParams();

  return (
    <>
        <Header/>
        <ListingDetail/>
        <Footer/>
    </>
  )
}
export default ListingViewDetail